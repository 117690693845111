import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { APPCONFIG } from '../../app.config';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { UserAction, UserInfo } from '../interfaces/user.interface';


@Injectable()
export class UserService {

  typeActionUser: UserAction;
  syncUserProfileInfo = new BehaviorSubject<UserInfo>({});



  constructor(private logger: LoggingService, private settings: SettingsService,
    private restful: RestfulService, private authService: AuthService) {

      this.authService.syncOnLoginEvent().subscribe(userIsLogged => {
        if (userIsLogged) {
          this.getUserInfo().subscribe(res => {
            const { outcome, data } = res;
            if (outcome.success) {
              this.syncUserInfo(data);
            }
          });
        }
      });
  }

  setTypeActionUser(typeActionUser: UserAction): void {
    this.typeActionUser = typeActionUser;
  }

  getTypeActionUser(): UserAction {
    return this.typeActionUser;
  }


  /* UTILS PER MANTENERE AGGIORNATA SIDEBAR */
  syncUserInfo(userInfo: UserInfo) {
    this.syncUserProfileInfo.next(userInfo);
  }
  getSyncUserInfo(): Observable<UserInfo> {
    return this.syncUserProfileInfo.asObservable();
  }


  usersList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.admin_userList;
    return this.post(endPoint, reqBody);
  }

  getUserInfoById(idUser: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userId, idUser);
    return this.get(endPoint);
  }

  getUserInfo(): Observable<any> {
    const endPoint = ENDPOINT.admin_user_userSession_info;
    return this.get(endPoint);
  }

  getIdResellerSelected(): string {
    return this.authService.getIdResellerChoosed();
  }

  deleteUser(idUser: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userId, idUser);
    return this.delete(endPoint);
  }

  createUser(reqBody: UserInfo): Observable<any> {
    return this.post(ENDPOINT.admin_user, reqBody);
  }

  editUserById(idUser: string, reqBody: UserInfo): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userId, idUser);
    return this.put(endPoint, reqBody);
  }

  editUser(reqBody: UserInfo): Observable<any> {
    const endPoint = ENDPOINT.admin_user_userSession_update;
    return this.put(endPoint, reqBody);
  }

  editPassword(reqBody: any): Observable<any> {
    return this.put(ENDPOINT.account_changepwd, reqBody);
  }

  invite(idUser: number): Observable<any> {
    const reqBody: any = {};
    const endPoint = getEndPointWithParams(ENDPOINT.admin_userInvite, idUser);
    return this.put(endPoint, reqBody);
  }

  getUserPrivilige(): Observable<any> {
    const endPoint = ENDPOINT.admin_user_privilige;
    return this.get(endPoint);
  }

  enableUser(idUser: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_user_enable, idUser);
    return this.put(endPoint, null);
  }

  disableUser(idUser: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_user_disable, idUser);
    return this.put(endPoint, null);
  }


  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}



