import { AfterViewInit, Component, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { PortalService } from '../../../core/services/portal.service';
import { Multiselect, MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { AngularMultiSelect } from '@khajegan/angular2-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sacbo-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss']
})
export class CustomerSelectComponent implements AfterViewInit {

  @ViewChild('dropdown', { static: false }) dropdown: AngularMultiSelect;

  @Input() customerSelected: MultiselectItem[] = [];

  @Output() customerSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: this.translate.instant('multiselect.filterSearch.placeholder'),
    noDataLabel: this.translate.instant('multiselect.filterSearch.noEmailAvailable'),
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false
  };

  customerList: MultiselectItem[] = [];

  constructor(
    private portalService: PortalService,
    private renderer: Renderer2,
    private translate: TranslateService
  ) { }

  ngAfterViewInit(): void {
    /** Subscription of the search input event, that fires the load of the list content when at least 2 characters are typed */
    const searchInput = this.dropdown.dropdownListElem?.nativeElement.querySelector('angular2-multiselect input[type="text"]');
    if (searchInput) {
      this.renderer.listen(searchInput, 'input', this.onSearchInputChange.bind(this));
    } else {
      console.warn('Search input element not found.');
    }
  }

  onCustomerSelect(): void {
    this.customerSelectedChange.emit(this.customerSelected);
    this.onSelected.emit(this.customerSelected);
  }

  clearCustomerList(): void {
    this.customerList = [];
  }

  // ---------- PRIVATE METHODS ---------- //

  /** The load method that calls the API is fired only when the user types more than 2 characters, in order to avoid page overloading */
  private onSearchInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= 2) {
      this.load(inputValue);
    } else {
      this.clearCustomerList();
    }
  }

  private load(inputValue: string) {
    this.portalService.getMultiselectItemsWithParam(Multiselect.CUSTOMER_EMAILS, inputValue).subscribe((response) => {
      this.customerList = response?.data.map(customer => {
        return {
          id: customer.id,
          itemName: customer.name
        } as MultiselectItem
      });
    }, (error) => {
      console.log("errore", error);
    });
  }

}
