import { Ticket } from './../interfaces/ticket.interface';
import { CodeRole } from './../interfaces/user.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MultiselectItem, SweetAlertMsg, LoggedUserInfo } from './../interfaces/admin.interface';
import { ProductGroupFamily, ProductThreshold } from './../interfaces/product-sale.interface';
import { LoggingService } from './log.service';
import { ToasterService } from 'angular-toaster';
import { SettingsService } from './../settings/settings.service';
import { TranslatorService } from './../translator/translator.service';
import { RestfulService } from './restful.service';
import { CommonService } from './../../shared/services/common.service';
import { CartItem, ThresholdAttributeInfo, PaymentMethod, CartItemType } from './../interfaces/cart-item.interface';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { getTotalPriceFromThreshold } from '../../../assets/js/util.js';
import { getEndPointWithParams, dateFormatting} from '../../../assets/js/util.js';
import { Injectable } from "@angular/core";
import { Product, ThresholdAttribute } from '../interfaces/product-sale.interface.js';
import { HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver/src/FileSaver.js';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  /* LOCAL VARIABLES */
  productGroupFamilyList = new BehaviorSubject<ProductGroupFamily[]>([]);
  cartList = new BehaviorSubject<CartItem[]>([]);
  totalItem = new BehaviorSubject<number>(null);
  ticketList = new BehaviorSubject<Ticket[]>([]);

  /* UTILS VARIABLES */
  selectedResellerIsCompany: MultiselectItem[] = [];
  selectedPriceList: MultiselectItem[] = [];

  paymentMethod: PaymentMethod;

  CodeRole = CodeRole;
  loggedUserInfo: LoggedUserInfo;

  currentLanguage: string;

  toastMessage = {
    cartNotLoaded: null,
    itemNotAdded: null,
    cartNotEmptied: null,
    itemNotRemoved: null,
    itemRemoved: null
  };

  swalMessage: SweetAlertMsg = {
    loadingText: null,
    creationText: null
  };



  constructor(private commonService: CommonService, private restful: RestfulService,
    private translator: TranslatorService, private settingService: SettingsService,
    private toasterService: ToasterService, private logService: LoggingService) {

    this.loggedUserInfo = commonService.getLoggedUserInfo();
    this.initializeService();
  }

  initializeService(): void {
    this.listenLanguageChange();
    this.handleGetCartList();
    this.handleGetProductList();
    this.setupTranslation();
  }

  listenLanguageChange(): void {
    this.currentLanguage = this.translator.translate.currentLang;
    this.commonService.dialogObservable.subscribe(lng => {
      if (lng !== "") {
        this.currentLanguage = lng;
      }
    });
  }

  setupTranslation(): void {
    this.translator.translate.stream('swal').subscribe(translate => {
      this.toastMessage.cartNotLoaded = translate.cart_not_loaded;
      this.toastMessage.itemNotAdded = translate.item_not_added;
      this.toastMessage.itemNotRemoved = translate.item_not_removed;
      this.toastMessage.itemRemoved = translate.item_removed;
      this.toastMessage.cartNotEmptied = translate.cart_not_emptied;

      this.swalMessage.loadingText = translate.op_in_progress;
      this.swalMessage.errorText = translate.product_added_er;
      this.swalMessage.creationText = translate.product_added_cart;
    });
  }

  /* UTILS */

  getTranslationAttributeValue(key: string, product: Product): string {
    if (product.translationAttribute && product.translationAttribute.length) {
      return product.translationAttribute.find(el => el.key === key) ? product.translationAttribute.find(el => el.key === key).value : '';
    }

    return '';
    
  }

  getProductList(): Observable<ProductGroupFamily[]> {
    return this.productGroupFamilyList.asObservable();
  }

  updateProductList(pgf: ProductGroupFamily[]): void {
    this.productGroupFamilyList.next(pgf);
  }

  getCartList(): Observable<CartItem[]> {
    return this.cartList.asObservable();
  }

  updateCartList(updatedList: CartItem[]): void {
    this.cartList.next(updatedList);
  }

  getTotalItem(): Observable<number> {
    return this.totalItem.asObservable();
  }

  updateTotalItem(total: number): void {
    this.totalItem.next(total);
  }

  getSelectedResellerIsCompany(): MultiselectItem[] {
    return this.selectedResellerIsCompany;
  }
  setSelectedResellerIsCompany(item: MultiselectItem): void {
    this.selectedResellerIsCompany = item ? [item] : [];
    this.handleGetProductList();
  }

  getSelectedPriceList(): MultiselectItem[] {
    return this.selectedPriceList;
  }
  setSelectedPriceList(item: MultiselectItem): void {
    this.selectedPriceList = item ? [item] : [];
    this.handleGetProductList();
  }

  calcTotalPriceForThresholdAttributeForCombo(thresholdAttributeList: ThresholdAttribute[], quantityArray: ThresholdAttributeInfo[]): number { 

    let totalPrice = 0;
    let totalCumulative = 0;
    let totalNoCumulative = 0;

    for (const thAt of thresholdAttributeList) {
      const thresholdAttributeId = thAt.id;
      const indexQntArr = quantityArray.findIndex(el => el.idAttribute === thresholdAttributeId);
      const quantity = quantityArray[indexQntArr] ? quantityArray[indexQntArr].quantity : 0;
  
      const groupedTh = Array.from(this.groupBy(thAt.productThresholds, prThCmb => prThCmb.idProductInCombo));
      
      for (const groupTh of groupedTh) {
        if (thAt.cumulative) {
          totalCumulative += getTotalPriceFromThreshold(quantity, groupTh[1]);
        } else {
          const price = groupTh[1].find(el => quantity >= el.min && (el.max !== null ? (quantity <= el.max) : true)).price;
          totalNoCumulative += quantity * price;
        }
      }

    }

    return totalPrice = totalCumulative + totalNoCumulative;

  }

  calcTotalPriceForThresholdAttribute(thresholdAttributeList: ThresholdAttribute[], quantityArray: ThresholdAttributeInfo[]): number {
    let totalPrice = 0;
    let totalCumulative = 0;
    let totalNoCumulative = 0;

    for (const thAt of thresholdAttributeList) {
      const thresholdAttributeId = thAt.id;
      if (quantityArray.findIndex(el => el.idAttribute === thresholdAttributeId) > -1) {
        const indexQntArr = quantityArray.findIndex(el => el.idAttribute === thresholdAttributeId);
        const chooseQuantity = quantityArray[indexQntArr].quantity;
        if (thAt.cumulative) {
          totalCumulative += getTotalPriceFromThreshold(chooseQuantity, thAt);
        } else {
          totalNoCumulative += this.getPriceForQuantity(thAt, chooseQuantity);
        }
      }
    }
    return totalPrice = totalCumulative + totalNoCumulative;
  }

  getPriceForQuantity(thresholdAttribute: ThresholdAttribute, qnt: number): number {
    const priceForThreshold = thresholdAttribute.productThresholds.find(el => qnt >= el.min && (el.max !== null ? (qnt <= el.max) : true)).price;
    return qnt * priceForThreshold;
  }

  setPaymentMethod(paymentMethod: PaymentMethod): void {
    this.paymentMethod = paymentMethod;
  }

  getTicketList(): Observable<Ticket[]> {
    return this.ticketList.asObservable();
  }

  setTicketList(ticketList: Ticket[]): void {
    this.ticketList.next(ticketList);
  }

  /* HANDLE */

  handleGetCartList() {
    const endpoint = getEndPointWithParams(ENDPOINT.order_cart_view, this.currentLanguage);
    this.get(endpoint).subscribe(res => {
      const { data, outcome, total } = res;
      if (outcome.success) {
        this.updateCartList(data ? data : []);
        this.updateTotalItem(total);
      }
    }, err => {
      this.toasterService.pop('error', this.toastMessage.cartNotLoaded);
    });
  }

  handleEmptyCart(): void {
    if (this.commonService.getLoggedUserInfo()) {
      const endpoint = getEndPointWithParams(ENDPOINT.order_cart_empty);
      this.delete(endpoint).subscribe(res => {
        const { outcome } = res;
        if (outcome.success) {
          this.cartList.next([]);
          this.handleGetCartList();
        }
      }, _ => {
        this.toasterService.pop('error', this.toastMessage.cartNotEmptied);
      });
    }
  }

  handleAddCartItem(cartItem: CartItem, bsModalRef?: BsModalRef, clear: boolean = true): Promise<void> {
    if (this.commonService.getLoggedUserInfo()) {
      const endpoint = getEndPointWithParams(ENDPOINT.order_cart_add, this.currentLanguage);
      return new Promise((resolve, reject) => {
        this.post(endpoint, cartItem).subscribe(res => {
          const { data, outcome, total } = res;
          if (outcome.success) {
            this.updateCartList(data ? data : []);
            this.updateTotalItem(total);
            if (clear) {
              if(bsModalRef)
                bsModalRef.hide();
              this.showSuccessAlert();
            }
            resolve();
          }
        }, err => {
          swal.fire(this.swalMessage.errorText, this.settingService.manageErrorMsg(err), "warning");
          reject();
        });
      });
    }
  }

  handleRemoveCartItem(cartItem: CartItem): void {
    if (this.commonService.getLoggedUserInfo()) {
      const endpoint = getEndPointWithParams(ENDPOINT.order_cart_remove, cartItem.id);
      this.delete(endpoint).subscribe(res => {
        const { outcome } = res;
        if (outcome.success) {
          this.handleGetCartList();
          this.toasterService.pop('success', this.toastMessage.itemRemoved);
        }
      }, err => {
        this.toasterService.pop('error', this.toastMessage.itemNotRemoved);
      });
    }
  }

  handleGetProductList(): void {
    const idReseller = this.selectedResellerIsCompany.length !== 0 ? this.selectedResellerIsCompany[0].id : null;
    const idPriceList = this.selectedPriceList.length !== 0 ? this.selectedPriceList[0].id : null;
    if (idReseller && idPriceList) {
      const endpoint = getEndPointWithParams(ENDPOINT.product_groupByFamily_idReseller_idPriceList, idReseller, idPriceList, 'EUR', this.currentLanguage);
      this.get(endpoint).subscribe(res => {
        const { data, outcome } = res;
        if (outcome.success) {
          this.updateProductList(data ? data : []);
        }
      }, err => {
        this.toasterService.pop('error', this.toastMessage.itemNotAdded);
      });
    }
  }

  handlePlaceOrder(paymentMethod, modify: boolean = false): Observable<any> {
    if (this.commonService.getLoggedUserInfo()) {
      const endpoint = getEndPointWithParams(ENDPOINT.order_cart_checkout, this.currentLanguage, paymentMethod);
      return this.put(modify ? `${endpoint}?mode=MODIFY` : endpoint, null);
    }
  }

  addCartItem( cartItem: CartItem, bsModalRef?: BsModalRef) {
    if (this.commonService.getLoggedUserInfo()) {
      const endpoint = getEndPointWithParams(ENDPOINT.order_cart_add, this.currentLanguage);
      return this.s1Post( endpoint, cartItem).pipe(map( res => {
        const { data, outcome, total } = res;
        if (outcome.success) {
          this.updateCartList(data ? data : []);
          this.updateTotalItem(total);
          if(bsModalRef)
            bsModalRef.hide();
          this.showSuccessAlert();
        }
      }))
    }
  }

  handleGenerateLot( request, cartItem ): void {
    swal.fire({
      title: this.swalMessage.loadingText,
      didOpen: (() => swal.showLoading())
    })
    const endpoint = getEndPointWithParams(ENDPOINT.order_cart_add, this.currentLanguage);
    const endpointEmptyCart = getEndPointWithParams(ENDPOINT.order_cart_empty);
    this.s1Delete(endpointEmptyCart).subscribe( res => {
      this.post(endpoint, cartItem).subscribe(res => {
        const { data, outcome, total } = res;
        const endpoint = ENDPOINT.order_cart_lotcheckout; 
        if (outcome.success) {
          this.putDownload( endpoint , request).subscribe(response => {
            swal.fire({
              icon: "success",
              title: this.translator.translate.instant('msg.success'),
            })
          });
        } else {
          swal.fire({
            icon: "error",
            title: this.translator.translate.instant('msg.error'),
            text: outcome.message,
          });
        }
      });
    })
  }

  generateLot( request ): Observable<any> {
    const endpoint = ENDPOINT.order_cart_lotcheckout;
    return this.s1PutDownload( endpoint , request);
  }

  emptyCart(mode?: string): Observable<any> {
    if (this.commonService.getLoggedUserInfo()) {
      const endpoint = mode ? getEndPointWithParams(`${ENDPOINT.order_cart_empty}?mode=${mode}`) : getEndPointWithParams(ENDPOINT.order_cart_empty);
      return this.s1Delete(endpoint);
    }
  }

  /* PRICE PORTERAGGIO */

  private getPriceToUse(prodTresh: ProductThreshold, isFull: boolean) {

    let priceToUse = prodTresh?.price;
    const priceReduced = prodTresh?.priceReduced;
    const discountInfo = prodTresh?.discountIncrease;

    if (isFull) {
      return priceToUse;
    }

    if (priceReduced != null && priceReduced != undefined && priceReduced < priceToUse) {
      priceToUse = priceReduced;
    }

    if (discountInfo && discountInfo.isWithDiscount && discountInfo.discountAmount !== undefined && discountInfo.discountAmount >= 0) {
      priceToUse = priceToUse - 1*discountInfo.discountAmount;
    }

    return priceToUse;

  }

  getTotalPriceFromThreshold(quantity: number, thresholdAttributeList: any, isReduced: boolean) {

    let partial = [];

    //valore delle threshold in caso sia cumulativo o no sui combo cambia la struttura del dato che arriva. Per cui in un caso gestisco l'oggetto productThresholds in altro no
    let pTh = thresholdAttributeList.productThresholds != undefined ? thresholdAttributeList.productThresholds : thresholdAttributeList;

    pTh.forEach(threshold => {

      let priceToUse = this.getPriceToUse(threshold, !isReduced);

      if (quantity > threshold.max && threshold.max !== null) {
        if (threshold.min === 0) {
            partial.push((threshold.max - threshold.min) * priceToUse);
        } else {
            partial.push((threshold.max - threshold.min + 1) * priceToUse);
        }
        return
      }

      if (quantity <= threshold.max && quantity >= threshold.min) {
        if (threshold.min === 0) {
            partial.push((quantity - threshold.min) * priceToUse);
        } else {
            partial.push((quantity - threshold.min + 1) * priceToUse);
        }
      }

      if (threshold.max === null && quantity >= threshold.min) {
        if (threshold.min === 0) {
            partial.push((quantity - threshold.min) * priceToUse);
        } else {
            partial.push((quantity - threshold.min + 1) * priceToUse);
        }
      }

    });

    return partial.reduce((prev, curr) => prev + curr);
  }

  calcTotalPrice(product: Product, quantityArray: ThresholdAttributeInfo[], isReduced: boolean): number {

    const quantityPositive = quantityArray.filter(qt => qt.quantity > 0).map(qt => qt.idAttribute);
    const tresholdPositive = product.thresholdAttribute.filter(thAt => quantityPositive.includes(thAt.id));

    let sum = 0;

    tresholdPositive.forEach(thAt => {

      const quantity = quantityArray.find(qt => qt.idAttribute == thAt.id)?.quantity ?? 0;

      //Se prodotto combo raggruppo 
      if (product.isCombo) {

        const groupedTh = this.groupBy(thAt.productThresholds, prThCmb => prThCmb.idProductInCombo);

        for (const groupTh of groupedTh) {
          if (thAt.cumulative) {
            sum += this.getTotalPriceFromThreshold(quantity, groupTh[1], isReduced);
          } else {

            const tresh = groupTh[1].find(el => quantity >= el.min && (el.max !== null ? (quantity <= el.max) : true));    
            //let priceToUse = (isReduced && priceReduced !== null && priceReduced !== undefined) ? priceReduced : price;
    
            let priceToUse = this.getPriceToUse(tresh, !isReduced);

            sum += quantity * priceToUse;
          }
        }
      } else {
        if (thAt.cumulative) {
          sum += this.getTotalPriceFromThreshold(quantity, thAt, isReduced);
        } else {

          const tresh = thAt.productThresholds.find(el => quantity >= el.min && (el.max !== null ? (quantity <= el.max) : true))
    
          //let priceToUse = (isReduced && priceReduced !== null && priceReduced !== undefined) ? priceReduced : price;
          let priceToUse = this.getPriceToUse(tresh, !isReduced);

          sum += quantity * priceToUse;
        }
      }
    });
    
    return sum;
  }


  /* SUCCESS ALERT */

  showSuccessAlert(): void {
    swal.fire({
      timer: 2000,
      title: this.swalMessage.creationText,
      icon: 'success',
      showConfirmButton: false
    });
  }



  /* RESTFUL METHOD */

  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  s1Post(endPoint: string, reqBody: any): Observable<any> {
    return this.restful.post(endPoint, reqBody).pipe(map( (response: HttpResponse<any>) => {
      this.responseHandler( response );
    }))
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  s1Delete(endPoint: string): Observable<any> {
    return this.restful.delete(endPoint).pipe(map((response: HttpResponse<any>) => {
      this.responseHandler( response );
    }))
  }

  get(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  putDownload(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.put_DownloadFile(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler_downloadFile(response, observer);
      });
    });
  }

  s1PutDownload(endPoint: string, reqBody: any): Observable<any> {
    return this.restful.put_DownloadFile(endPoint, reqBody).pipe(map( (response) => {
      this.responseHandler_downloadFile( response );
    }))
  }

  get_mck(endPoint: string): Observable<any> {
    console.log('testService 2')
    return this.restful.get(endPoint)
  }

  responseHandler(response: HttpResponse<any>, observer?: any) {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logService.log("Service:", "SUCCESS", 200);
      if( observer )
        observer.next(response);
      else
        return response;
    } else {
      this.logService.log("Service:", "FAILURE", 200);
      outcome.message = this.settingService.manageErrorMsg(outcome);
      if( observer )
        observer.error(outcome);
      else
        return throwError(outcome);
    }
  }

  responseHandler_downloadFile(response: HttpResponse<any>, observer?: any) {
    saveAs(response, "massive_tickets_"+dateFormatting(new Date())+".xls");
    const outcome = response['outcome'];
    if( !outcome ) {
      if( observer )
        observer.next(response);
      else 
        return response;
    } else {
      return throwError(outcome);
    }
  }

  s1ResponseHandler(response: HttpResponse<any>) {

  }

  /**
   * @description
   * Takes an Array<V>, and a grouping function,
   * and returns a Map of the array grouped by the grouping function.
   *
   * @param list An array of type V.
   * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
   *                  K is generally intended to be a property key of V.
   *
   * @returns Map of the array grouped by the grouping function.
   */
  //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  //    const map = new Map<K, Array<V>>();
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }

}
