import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from '../../../core/interfaces/cart-item.interface';

@Component({
  selector: 'sacbo-porteraggio-detail-section',
  templateUrl: './porteraggio-detail-section.component.html',
  styleUrls: ['./porteraggio-detail-section.component.scss']
})
export class PorteraggioDetailSectionComponent implements OnInit {

  @Input() item: CartItem;
  @Input() fullVersion: boolean = true; 

  constructor() { }

  ngOnInit(): void { }

}
