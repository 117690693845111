import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, Multiselect } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';

@Component({
  selector: 'sacbo-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent implements OnInit {

  @Input() set preselectedCompany(newValue: number) {
    this.selectFromId(newValue);
  }

  @Input() companySelected: MultiselectItem[] = [];

  @Input() set disabled(newValue: boolean) {

    this.multiselectSettings_base.disabled = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };

  };

  @Output() companySelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  list: MultiselectItem[] = [];

  preselectedId: number;

  constructor(public portalService: PortalService) { 

    this.load();

  }

  ngOnInit(): void { }

  onElementSelect(): void {
    this.companySelectedChange.emit(this.companySelected);
    this.onSelected.emit(this.companySelected);
  }

  load() {

    this.portalService.getMultiselectItems(Multiselect.COMPANY).subscribe((response) => {

      this.list = response?.data.map(company => {

        return {
          id: company.id,
          itemName: company.name
        } as MultiselectItem

      })

      if (this.preselectedId && this.companySelected.length == 0) {
        this.selectFromId(this.preselectedId);
      }

    },
    (error) => {
      console.log("errore", error);
    });

  }

  selectFromId(id: number, withEvent: boolean = true) {

    this.preselectedId = id;

    this.companySelected = this.list.filter(c => c.id == id);

    if (withEvent) {
      this.onElementSelect();
    }

  }

}
