import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { S1UIService } from './s1-ui.service';
import { Router } from '@angular/router';
import { LoggingService } from '../../core/services/log.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { saveAs } from 'file-saver/src/FileSaver.js';
import { dateFormatting } from '../../../assets/js/util.js';
import { TranslateService } from '@ngx-translate/core';

// Call interfaces
export interface IS1SearchParams {
  sortBy?: string,
  isSortAscending?: boolean,
  page: number,
  pageSize: number
}

export interface IS1SearchParamsAlt {
  orderBy?: string,
  direction?: string,
  paging: boolean,
  page: number,
  rows: number
}

// Response interfaces
export interface IS1PaginationInfo {
  actualPage: number
  next: boolean
  pageSize: number
  previous: boolean
  totalItems: number
  totalPages: number
}

export interface IS1PaginatedResult {
  paginationInfo: IS1PaginationInfo
}

interface IS1Outcome {
  success: boolean,
  errorMessage: string,
  message?: string,
  description?: string
  code?: string
}

interface IS1Response extends HttpResponse<any> {
  outcome: IS1Outcome
  item?: any
  results?: any[]
  data?: {
    results?: any[]
  } | any
  paginationInfo?: IS1PaginationInfo
  total?: number
}

@Injectable({
  providedIn: 'root'
})
export class S1HttpClientService {

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private ui: S1UIService,
    private router: Router,
    private translator: TranslatorService,
    private translate: TranslateService
  ) { }

  /**
   * Wrapper of `HTTPClient` post function that centralize the managment of base url, errors and UI feedback
   * 
   * @param path relative path of the API call.
   * @param body JSON body of the API call.
   * @param showUI determine to show or not UI feedback
   * @returns `Observable` of type `IS1Response`
   */
  post(path: string, body: any, showUI: boolean = true, manageUnauthorized: boolean = true, isBoUrl: boolean = true): Observable<IS1Response> {


    this.logger.log("POST: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.post<any>((isBoUrl ? environment.restBaseUrl : environment.ecommBaseUrl) + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI, manageUnauthorized))
      )
  }

  get(path: string, urlParameters: any = {}, showUI: boolean = false, manageUnauthorized: boolean = true, isBoUrl: boolean = true): Observable<IS1Response> {

    const parameters = this.mapParameters(urlParameters)

    this.logger.log("GET: ", path, 200)
    this.logger.log("Par: ", parameters, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.get<IS1Response>((isBoUrl ? environment.restBaseUrl : environment.ecommBaseUrl) + path + parameters, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI, manageUnauthorized))
      )

  }

  put(path: string, body: any, showUI: boolean = true, manageUnauthorized: boolean = true, isBoUrl: boolean = true): Observable<IS1Response> {

    this.logger.log("PUT: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.put<any>((isBoUrl ? environment.restBaseUrl : environment.ecommBaseUrl) + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI, manageUnauthorized))
      )
  }

  delete(path: string, showUI: boolean = true, isBoUrl: boolean = true): Observable<IS1Response> { 

    this.logger.log("DELETE: ", path, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.delete<any>((isBoUrl ? environment.restBaseUrl : environment.ecommBaseUrl) + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )

  }

  downloadPost(path: string, body: any = {}, showUI: boolean = true, replaceUrl: string = null): Observable<any> {

    const baseUrl = replaceUrl ? environment.restBaseUrl.replace("/webapp", replaceUrl) : environment.restBaseUrl;

    if (showUI) {
      this.ui.showSpinner();
    }

    const token = localStorage.getItem("token") != null ? localStorage.getItem("token") : '';

    return this.http.post(baseUrl + path, body, { headers: { 'X-Auth-Token': token }, responseType: 'blob' })
      .pipe(
        map((response: any) => {

          if (!response) {

            throw new HttpErrorResponse({
              status: +status,
              statusText: this.translate.instant('s1.downloadError'),
              headers: null,
              url: null
            })

          }

          if (showUI) {
            this.ui.closeSpinner()
          }

          return response;

        }),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )

  }

  download(path: string, urlParameters: any = {}, showUI: boolean = true, isBoUrl: boolean = true, replaceUrl: string = null): Observable<Blob> {

    const parameters = this.mapParameters(urlParameters)

    if (showUI) {
      this.ui.showSpinner()
    }

    const url = (isBoUrl
      ? replaceUrl ? environment.restBaseUrl.replace("/webapp", replaceUrl) : environment.restBaseUrl
      : replaceUrl ? environment.restBaseUrl.replace("/ecommerce", replaceUrl) : environment.ecommBaseUrl
    ) + path + parameters;
    
    this.logger.log("DOWNLOAD: ", path, 200)
    this.logger.log("Par: ", parameters, 200)

    const token = localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
//'ApiKey': environment.apiKeyFatturAttiva
    return this.http.get(url, { headers: { 'X-Auth-Token': token }, responseType: 'blob' })
      .pipe(
        map((response: Blob) => {

          console.log("RESPONSE: ", response)

          if (!response) {

            throw new HttpErrorResponse({
              status: +status,
              statusText: this.translate.instant('s1.downloadError'),
              headers: null,
              url: null
            })

          }

          if (showUI) {
            this.ui.closeSpinner()
          }

          return response;

        }),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )

  }

  private mapParameters(parametersObj: any): string {

    var parameterString = "?";

    for (var key in parametersObj) {
      if (parameterString != "?") {
        parameterString += "&";
      }

      const value = parametersObj[key]

      if (value != null) {
        parameterString += key + "=" + encodeURIComponent(value);
      }
    }

    if (parameterString == "?") {
      return ""
    }

    return parameterString

  }

  private handleResponse(response: IS1Response, showUI: boolean = true) {

    const s1Response = response

    this.logger.log("Response: ", s1Response, 200)

    if (s1Response.outcome && s1Response.outcome.success) {

      if (showUI) {
        this.ui.closeSpinner()
      }

      return s1Response
      
    } else {

      const code = s1Response.outcome.code

      throw new HttpErrorResponse({
        status: 499,
        statusText: code ? ("error.e_" + code)  : (s1Response.outcome.errorMessage ?? s1Response.outcome.message),
        headers: s1Response.headers,
        url: s1Response.url
      })

    }

  }

  private handleError(error: HttpErrorResponse, showUI: boolean = true, manageUnauthorized: boolean = true) {

    this.logger.log("HTTP Error: ", error, 200)

    if (showUI) {
      this.ui.showHTTPErrorPopup(error)
    }

    if (manageUnauthorized) {
      switch(error.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
          localStorage.clear();
          this.router.navigate(["/login/1"]);
          break;
      }
    }

    return throwError(error)

  }

  private getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Accept-Language': this.translator.getLanguageInUse() ?? "",
        //'Authorization': 'Bearer ' + (this.s1Session.getItem("token") ?? ""),
        'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };

    return httpOptions;
  }

  private getRequestOptionArgsFatturaAttiva(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        //'Accept-Language': this.translator.getLanguageInUse() ?? "",
        //'Authorization': 'Bearer ' + (this.s1Session.getItem("token") ?? ""),
        
        'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };

    return httpOptions;
  }

  saveFile(file: Blob, name: string) {

    /*const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.download = name; // "stampa_" + stampa.id + "." + extension;
    a.href = url;
    a.click();
    URL.revokeObjectURL(url);*/

    saveAs(file, name);
    
    return true 

  }

}