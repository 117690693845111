import { Ticket } from './../interfaces/ticket.interface';
import { TranslationAttribute, AttributeAction } from './../interfaces/product.interface';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { ProductAction, FamilyAction, ProductInfo, ProductFamily } from '../interfaces/product.interface';
import { Multiselect } from '../interfaces/admin.interface';
import { Product } from '../interfaces/product-sale.interface';

export interface IProductUpdateImage {
  id?: number; // Nel caso in cui sia un primo upload questo è NULL
  idProduct: number;
  key: string;
  enabled: boolean;
  extension: string;
  fileEncoded: string; // base64 dell'immagine da uploadare
  languageCode: string;
}

@Injectable()
export class ProductService {

  typeActionProduct: ProductAction;
  typeActionFamily: FamilyAction;
  typeActionAttribute: AttributeAction;

  ticket: Ticket;

  tempTranslationAttribute: TranslationAttribute;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }



  setTypeActionFamily(typeActionFamily: FamilyAction): void {
    this.typeActionFamily = typeActionFamily;
  }

  getTypeActionFamily(): FamilyAction {
    return this.typeActionFamily;
  }

  setTypeActionProduct(typeActionProduct: ProductAction): void {
    this.typeActionProduct = typeActionProduct;
  }

  getTypeActionProduct(): ProductAction {
    return this.typeActionProduct;
  }

  setTypeActionAttribute(typeActionAttribute: AttributeAction): void {
    this.typeActionAttribute = typeActionAttribute;
  }

  getTypeActionAttribute(): AttributeAction {
    return this.typeActionAttribute;
  }

  /** Method that get a specific product or family of products (product by default, family param to true for family)  */
  getProductById(idProduct: number, family: boolean = false): Observable<any> {
    const endPoint = getEndPointWithParams(family ? ENDPOINT.product_productFamily_idFamily : ENDPOINT.product_idProduct, idProduct);
    return this.get(endPoint);
  }

  getProductsInfo(idProducts: number[]): Observable<any> {
    const endPoint = ENDPOINT.product_productsInfo;
    return this.post(endPoint, idProducts);
  }

  getProductList(reqBody: any): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_listView);
    return this.post(endPoint, reqBody);
  }

  createRegistryProduct(reqBody: ProductInfo): Observable<any> { // modificare per BGY TOP
    const endPoint = ENDPOINT.product_createProduct;
    return this.post(endPoint, reqBody);
  }

  updateRegistryProduct(reqBody: ProductInfo): Observable<any> { // modificare per BGY TOP
    const endPoint = ENDPOINT.product_updateRegistry;
    return this.put(endPoint, reqBody);
  }

  createThresholdAttribute(reqBody: ProductInfo): Observable<any> {
    const endPoint = ENDPOINT.product_create_ThresholdAttribute;
    return this.post(endPoint, reqBody);
  }

  updateThresholdsAttribute(reqBody: ProductInfo): Observable<any> {
    const endPoint = ENDPOINT.product_update_ThresholdAttribute;
    return this.post(endPoint, reqBody);
  }

  getFamilyList(reqBody: any): Observable<any> {
    const endPoint = ENDPOINT.product_productFamily;
    return this.post(endPoint, reqBody);
  }

  createProductFamily(reqBody: ProductFamily): Observable<any> {
    const endPoint = ENDPOINT.product_createProductFamily;
    return this.post(endPoint, reqBody);
  }

  getProductFamilyById(idProductFamily: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_idProductFamily, idProductFamily);
    return this.get(endPoint);
  }

  updateProductFamily(reqBody: ProductFamily): Observable<any> {
    const endPoint = ENDPOINT.product_updateProductFamily;
    return this.put(endPoint, reqBody);
  }

  activateProductFamily(idProductFamily: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_activateProductFamily, idProductFamily);
    return this.get(endPoint);
  }

  deleteProductFamily(idProductFamily: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_deleteProductFamily, idProductFamily);
    return this.delete(endPoint);
  }

  getAttributeList(reqBody: any) {
    const endPoint = getEndPointWithParams(ENDPOINT.translationAttribute_listView);
    return this.post(endPoint, reqBody);
  }

  createAttributeTranslation(reqBody: TranslationAttribute): Observable<any> {
    const endPoint = ENDPOINT.translationAttribute_create;
    return this.post(endPoint, reqBody);
  }

  setTranslationAttributeToEdit(translationAttribute: TranslationAttribute): void {
    this.tempTranslationAttribute = translationAttribute;
  }

  getTranslationAttribute(): TranslationAttribute {
    return this.tempTranslationAttribute;
  }

  updateTranslationAttribute(reqBody: TranslationAttribute): Observable<any> {
    const endPoint = ENDPOINT.translationAttribute_update;
    return this.post(endPoint, reqBody);
  }


  getMultiselectItems(multiselectType: Multiselect, id?: number): Observable<any> {
    let endPoint;
    switch (multiselectType) {
      case Multiselect.PRODUCT_FAMILY:
        endPoint = getEndPointWithParams(ENDPOINT.multiselect_familyList_idCompany, id);
        break;
    }
    return this.get(endPoint);
  }

  /** Method that connects an image to a product or a family of products (product by default, family param to true for family) */
  updateProductImage(params: IProductUpdateImage, family: boolean = false): Observable<any> {
    const endPoint = family ? ENDPOINT.product_productFamily_update_image : ENDPOINT.product_update_media;
    return this.post(endPoint, params);
  }

  /** Method that deletes a product / family image (product by default, family param to true for family) */
  deleteProductImage(idImage: number, family: boolean = false): Observable<any> {
    const endPoint = family ? ENDPOINT.product_productFamily_delete_image : ENDPOINT.product_delete_media;
    return this.delete(getEndPointWithParams(endPoint, idImage));
  }

  /** REST METHODS */

  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

  calculateExternalProductTotal(externalProduct: Product): number {
    const price = externalProduct.thresholdAttribute[0]?.productThresholds[0]?.price;
    const discount = externalProduct.thresholdAttribute[0]?.productThresholds[0]?.discountIncrease?.discountAmount;
    return discount ? price - (+discount) : price;
  }
}



