import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IS1SearchParamsAlt, S1HttpClientService } from '../../s1/services/s1-http-client.service';
import { IKeyMedia } from '../models/media';

export interface IListaMediaParams extends IS1SearchParamsAlt {
  filters?: {
    MEDIA: {
      key?: string // ex. "BANNER_PDF_TICKET",
      name?: string, //nome del file su cui viene fatto il like
      fromDate?: string, //Formato ex: "2022-08-01T00:00:00.000Z"
      toDate?: string, //Formato ex: "2022-08-01T00:00:00.000Z"
      languageCode?: string, //Possibili valori "it" o "en"
      type?: string //Possibili valori "ALL" o "RANDOM"
    }   
  }
}

export interface IListaKeyMediaParams extends IS1SearchParamsAlt {
  filters?: {
    MEDIA: {
      key?: string // ex. BANNER_PDF_TICKET
      enabled?: boolean
    }   
  }
}

export interface IUploadMediaParams {
  languageCode: string,
  key: string,
  extension: string,
  fileEncoded: string,
  name: string
}

export interface IAttributeInfoFromItemParams {
  permalink: string;
  itemCode: string;
  lang: string;
  keyAttribute: string;
}

export interface MediaFilters {
  key: string;
  type: string;
}

enum MediaApi {
  lista = "/all",
  download = "/download",
  listaKey = "/key/all",
  key = "/key",
  multiselectKey = "/multiselect/media_key",
  attributeInfoFromItem = "/:permalink/product_attribute/:itemCode/:lang/:keyAttribute",
  imageEcommerce = "/:permalink/product_attribute/download/:idProduct/:idAttribute"
}
@Injectable({
  providedIn: 'root'
})
export class MediaApiService {

  path = "/media"

  constructor(private s1HttpClient: S1HttpClientService) { }

  public searchListaMedia(params: IListaMediaParams): Observable<any> {

    return this.s1HttpClient.post(this.path + MediaApi.lista, params, false).pipe(
      map(response => response)
    )

  }

  public searchListaKeyMedia(params: IListaKeyMediaParams): Observable<any> {

    return this.s1HttpClient.post(this.path + MediaApi.listaKey, params, false).pipe(
      map(response => response)
    )

  }

  public multiselectKeyMedia(): Observable<any> {

    return this.s1HttpClient.get(MediaApi.multiselectKey).pipe(
      map(response => response)
    )

  }

  public createMedia(params: IUploadMediaParams): Observable<boolean> {

    return this.s1HttpClient.post(this.path, params).pipe(
      map(response => response.outcome.success)
    )

  }

  public deleteMedia(idMedia: number): Observable<boolean> {

    return this.s1HttpClient.delete(this.path + "/" + idMedia).pipe(
      map(response => response.outcome.success)
    )

  }

  public getImage(idMedia: number): Observable<any> {

    return this.s1HttpClient.download(this.path + MediaApi.download +  "/" + idMedia, null, false).pipe(
      map(response => response)
    )

  }

  public getMedia(language: string, filters?: MediaFilters): Observable<any> {
    const params: IListaMediaParams = {
      paging: false,
      page: 1,
      rows: 99,
      orderBy: "mediaKey",
      direction: "ASC",
      filters: {
        MEDIA: {
          key: filters ? filters?.key : null,
          name: null,
          fromDate: null,
          toDate: null,
          languageCode: language,
          type: filters ? filters?.type : "RANDOM"
        }
      }
    };

    return this.searchListaMedia(params).pipe(
      switchMap(response => {
        
        if (response && response.data && response.data.length) {
          return this.getImage(response.data[0].id);
        }
      })
    );

  }

  public getRandomBanner(language: string): Observable<any> {

    const params: IListaMediaParams = {
      paging: false,
      page: 1,
      rows: 99,
      orderBy: "mediaKey",
      direction: "ASC",
      filters: {
        MEDIA: {
          key: "BANNER_PDF_TICKET",
          name: null,
          fromDate: null,
          toDate: null,
          languageCode: language,
          type: "RANDOM"
        }   
      }
    };

    return this.searchListaMedia(params).pipe(
      switchMap(response => {
        
        if (response && response.data && response.data.length) {

          return this.getImage(response.data[0].id);

        } else {

          throw new HttpErrorResponse({
            status: 499,
            statusText: "",
            headers: response.headers,
            url: response.url
          });
    
        }
      
      })

    );

  }

  public createKey(params: IKeyMedia): Observable<boolean> {

    return this.s1HttpClient.post(this.path + MediaApi.key, params).pipe(
      map(response => response.outcome.success)
    )

  }

  public updateKey(params: IKeyMedia): Observable<boolean> {

    return this.s1HttpClient.put(this.path + MediaApi.key, params).pipe(
      map(response => response.outcome.success)
    )

  }

  imageFromItem(params: IAttributeInfoFromItemParams): Observable<any> {

    let url: string = MediaApi.attributeInfoFromItem;

    for (let key in params) {
      url = url.replace(`:${key}`, params[key]);
    }

    return  this.s1HttpClient.get(url, null, false, false, false).pipe(
      switchMap(response => {
        if (response && response.data && response.data.length) {

          const httpOptions = {
            headers: new HttpHeaders({
              //'Content-Type': 'application/json',
              //'Accept-Language': this.translator.getLanguageInUse() ?? "",
              //'Authorization': 'Bearer ' + (this.s1Session.getItem("token") ?? ""),
              
              'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
            })
          };

          const paramsI = {
            permalink: params.permalink,
            idProduct: response.data[0].idProduct,
            idAttribute: response.data[0].id
          }

          let urlI: string = MediaApi.imageEcommerce;

          for (let key in paramsI) {
            urlI = urlI.replace(`:${key}`, paramsI[key]);
          }

          return this.s1HttpClient.download(urlI, null, false, false).pipe(
            map(response => response)
          );

        } else {
          return of(null);
        }
      })
    );

  }

}
