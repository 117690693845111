import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 's1-date-picker',
  templateUrl: './s1-date-picker.component.html',
  styleUrls: ['./s1-date-picker.component.scss']
})
export class S1DatePickerComponent implements OnInit {

  @Input() date: Date;
  @Input() label: string;
  @Input() dark: boolean = false;
  @Input() readonly: boolean = false;

  @Input() set minDate(newValue: Date) {
    this.datepicker.minDate = newValue
  }

  @Input() set maxDate(newValue: Date) {
    this.datepicker.maxDate = newValue
  }

  @Input() set datesDisabled(newValue: Date) {
    this.datepicker.datesDisabled = newValue
  }

  @Output() dateChange = new EventEmitter<Date>();

  datepicker: any = {
    config: {
      customTodayClass: 'custom-today-class',
      dateInputFormat: 'DD/MM/YYYY',
      isAnimated: true,
      adaptivePosition: true,
      containerClass: 'theme-dark-blue'
    },
    maxDate: null,
    minDate: null,
    datesDisabled: null
  };

  constructor() { }

  ngOnInit() {
  }

  updateDate() {
    this.dateChange.emit(this.date);
  }

}
