import { AfterSale, CustomFieldsExternalPark, TranslationAttribute } from './product-sale.interface';
import { ValidityTime } from './product.interface';
import { SelectedProductItem } from './selector.interface';
export enum RetireOption {
  HOME = "HOME",
  AIRPORT = "AIRPORT"
}

export enum RetireOptionOnly {
  HOME = "HOME"
}

export enum PaymentMethod {
  CASH = "CASH",
  POS = "POS",
  NONE = "NONE"
}

export enum PaymentMethodValues {
  CASH = "CASH",
  POS = "POS",
  NONE = "NONE"
}

export enum CartItemType {
  BUY = "BUY",
  MODIFY = "MODIFY"
}

export interface CartItem {
  id?: number;
  idProductType?: number;
  idProduct?: number;
  idPriceList?: number;
  productTypeName?: string;
  productIdentificationName?: string;
  vat?: number;
  image?: string;
  codeFamily?: string;
  currency?: string;
  total?: number;
  totalTaxable?: number;
  totalVat?: number;
  amountDue?: number;
  additionalInfo?: AdditionalInfo;
  additionalInfoExternal?: AdditionalInfoExternal,
  quantity?: number;
  itemCode?: string;
  mode?: string;
  deleted?: boolean,
  translationAttribute?: TranslationAttribute[],
  // temporanee
  cartDescription?: string;
  isLot?: boolean;
}

export interface ThresholdAttributeInfo {
  idAttribute?: number;
  name?: string;
  quantity?: number;
  carnet?: number;
}

export interface AdditionalInfo {
  quantityAttribute?: ThresholdAttributeInfo[];
  withdrawalData?: WithdrawalData;
  accountHolder?: string;
  cards?: VipCardItem[];
  date?: number;
  flight?: string;
  nameLeader?: string;
  note?: string;
  ticketOption?: TicketOption;
  obliterations?: Obliterations;
  remainingObliterations?: Obliterations;
  attribute?: TicketAttribute[];
  destination?: string;
  airline?: string;
  porterageData?: IPorterageData;
}

export interface IPorterageData {
  name: string,
  surname: string,
  email: string,
  phone: string,
  flightNumber: string,
  flightDate: number,
  details: IPorterageDataDetail[]
}

export interface IPorterageDataDetail {
  code: string,
  idAttribute?: number;
  name?: string;
  quantity?: number;
}

export interface AdditionalInfoProduct {
  identificationName?: string;
  img?: string;
  itemCode?: string;
  id?: string;
  externalTicketType?: string;
  translationAttribute?: TranslationAttribute[];
}

export interface AdditionalInfoPrice {
  quantity?: number;
  total?: number;
  totalTaxable?: number;
  totalVat: number;
  unitPrice: number;
  unitPriceNet: number;
  unitVat: number;
  vat: number;
}

export interface AdditionalInfoPriceList {
  id?: number;
  name?: string;
}

export interface AdditionalInfoExternal {
  shortCodeRef?: string,
  proposal?: ParkProposal,
  customFieldsExternal?: CustomFieldsExternalPark,
  codeFamily?: string
}

export interface AdditionalInfoOrderDetail {
  idOrder?: number;
  idOrderDetail?: number;
  idOrderNumber?: number;
  afterSale?: AfterSale
}

export interface ParkProposal {
  boardingPass: string;
  carPlate: string;
  name: string;
  surname: string;
  paxNumber: number;
  email: string;
}

export interface VipCardItem {
  idAttribute?: number;
  accountHolder?: string;
  cardNumber?: number;
}

export interface WithdrawalData {
  city: string;
  province: string;
  zipCode: string;
  address: string;
}

export interface TicketOption {
  validityTime?: ValidityTime;
}

export interface Obliterations {
  ACALL?: number;
  FSTK?: number;
  VLSCB?: number;
  VLGIS?: number;
}

export interface TicketAttribute {
  idAttribute?: number;
  name?: string;
  quantity?: number;
}
