<div class="form-group mb-0">
  <label *ngIf="label" for="codeThreshold">{{ label | translate }}</label>
  <input class="form-control" autocomplete="off"
      required
      type="text"
      name="from_date"
      placeholder="{{'form.select_date' | translate}}"
      [(ngModel)]="date"
      (ngModelChange)="updateDate()"
      #dp="bsDatepicker"
      bsDatepicker
      [bsConfig]="datepicker.config"
      [maxDate]="datepicker.maxDate"
      [minDate]="datepicker.minDate"
      [datesDisabled]="datepicker.datesDisabled"
      [ngClass]="{dark: dark}"
      [readOnly]="readonly"/>
</div>
