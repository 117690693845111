import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, Multiselect } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';

@Component({
  selector: 'sacbo-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  @Input() userSelected: MultiselectItem[] = [];

  @Output() userSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    lazyLoading: true
  };

  usersList: MultiselectItem[] = [];

  constructor(public portalService: PortalService) { 
    // this.load();
  }

  ngOnInit(): void { }

  onUserSelect(): void {
    this.userSelectedChange.emit(this.userSelected);
    this.onSelected.emit(this.userSelected);
  }

  load() {
    this.portalService.getMultiselectItems(Multiselect.EMAIL).subscribe((response) => {
      this.usersList = response?.data.map(user => {
        return {
          id: user.id,
          itemName: user.name
        } as MultiselectItem
      });
    }, (error) => {
      console.log("errore", error);
    });
  }

}
