import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { MediaApiService } from '../../services/media-api.service';

@Component({
  selector: 'sacbo-media-key-select',
  templateUrl: './media-key-select.component.html',
  styleUrls: ['./media-key-select.component.scss']
})
export class MediaKeySelectComponent implements OnInit {

  @Input() keySelected: MultiselectItem[] = [];
  @Output() keySelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();
  @Input() set singleSelection(newValue: boolean) {

    if (newValue != null) {
      this.multiselectSettings_base.singleSelection = newValue;
    }
    
  }

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  keysList: MultiselectItem[] = [];

  constructor(private mediaApiService: MediaApiService) { }

  ngOnInit() {
    this.loadMultiselectItems();
  }

  loadMultiselectItems(): void {

     this.mediaApiService.multiselectKeyMedia().subscribe(response => {

      const { data } = response;

      if (data) {
        this.keysList = data.map(key => {
          return {
            id: key.id,
            itemName: key.name
          } as MultiselectItem
        })
      }

    });
  }

  onKeySelect(): void {
    this.keySelectedChange.emit(this.keySelected);
    this.onSelected.emit(this.keySelected);
  }

}
