import { Component, Input } from '@angular/core';

@Component({
  selector: 's1-spinner',
  templateUrl: './s1-spinner.component.html',
  styleUrls: ['./s1-spinner.component.scss']
})
export class S1SpinnerComponent {

  @Input() show: boolean = false;

}
