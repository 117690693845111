<div class="form-group">
  <label for="prod-m">{{ 'form.discountTypology'| translate }}</label>
  <angular2-multiselect [data]="typeList"
      [(ngModel)]="typologySelected"
      [settings]="multiselectSettings_base"
      (onSelect)="onTypeSelect()"
      (onSelectAll)="onTypeSelect()"
      (onDeSelect)="onTypeSelect()"
      (onDeSelectAll)="onTypeSelect()">
  </angular2-multiselect>
</div>