<div class="row">
  <s1-date-picker class="col" [(date)]="dateFrom" [label]="'coupons.label.dateFrom'" [maxDate]="dateTo"></s1-date-picker>
  <s1-date-picker class="col" [(date)]="dateTo" [label]="'coupons.label.dateTo'" [minDate]="dateFrom"></s1-date-picker>
  <s1-button class="col-2 pt-4 text-right" [disabled]="!dateFrom || !dateTo" [type]="s1ButtonType.New" [label]="'base.add'" (onClick)="add()" [icon]="'no'"></s1-button>
</div>
<div class="row bg-info mt-4" *ngIf="invalidDates?.length">
  <div class="col h4 pt-2">{{ 'coupons.label.dateFrom' | translate }}</div>
  <div class="col h4 pt-2">{{ 'coupons.label.dateTo' | translate }}</div>
  <div class="col-2"></div>
</div>
<div class="row" *ngFor="let dates of invalidDates; let i = index">
  <div class="col h4 pt-3">{{ dates.dateFrom | s1ReadableDate }}</div>
  <div class="col h4 pt-3">{{ dates.dateTo | s1ReadableDate }}</div>
  <s1-button class="col-2 pt-2 text-right" [type]="s1ButtonType.Delete" [label]="'base.delete'" (onClick)="askDelete(i)" [icon]="'no'"></s1-button>
</div>