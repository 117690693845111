// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // restBaseUrl: 'http://125.63.101.166:8081/bambos-rest/rest',
  // restBaseUrl: 'http://d5.iworklab.com:8081/bambos-rest/rest',
  // restBaseUrl: 'https://testbambos.soluzione1.it/bambos-rest/rest',
  // restBaseUrl: 'http://localhost:8080/bambos-rest/rest/webapp',
  restBaseUrl: 'https://testapi-sacbo.soluzione1.it/sacbo-rest/rest/webapp',
  ecommBaseUrl: 'https://testapi-sacbo.soluzione1.it/sacbo-rest/rest/ecommerce',
  rows: 10,
  marketPlace: "https://testweb-sacbo.soluzione1.it/",
  cdnImagesUrl: 'https://testcdn.soluzione1.it/sacbo/images/',
  version: "2.1.0 T (Testing)"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

