import { ThemesService } from './../themes/themes.service';
import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { getApplicationVersion } from '../../../assets/js/util.js'
import { environment } from '../../../environments/environment';
declare var $: any;

@Injectable()
export class SettingsService implements OnInit {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;

    constructor (
      private translate: TranslateService,
      private router: Router,
      private themeService: ThemesService
    ) {
        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings - footer
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'Sacbo - Backoffice',
            year: ((new Date()).getFullYear()),
            version: "v. FE " + environment.version
        };

        // Layout Settings - basic settings for layout & Co.
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    async ngOnInit(): Promise<void> {
      this.errorTranslation = await this.translate.get('error').toPromise();
    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    sessionExpired() {
      localStorage.clear();
    }

    manageErrorMsg(outcome) {
      this.errorTranslation = this.translate.get('error');
      let message = "";
      switch (outcome.code) {
        case '0000':
          message = this.errorTranslation.e_0000;
        break;
        case '0001':
          message = this.errorTranslation.e_0001;
        break;
        case '0002':
          message = this.errorTranslation.e_0002;
        break;
        case '0003':
          message = this.errorTranslation.e_0003;
        break;
        case '0004':
          message = this.errorTranslation.e_0004;
        break;
        case '0005':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
          message = this.errorTranslation.e_0005;
        break;
        case '0006':
          message = this.errorTranslation.e_0006;
        break;
        case '0007':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
          message = this.errorTranslation.e_0007;
        break;
        case '0008':
          message = this.errorTranslation.e_0008;
        break;
        case '0009':
          message = this.errorTranslation.e_0009;
        break;
        case '0010':
          message = this.errorTranslation.e_0010;
        break;
        case '0011':
          message = this.errorTranslation.e_0011;
        break;
        case '0012':
          message = this.errorTranslation.e_0012;
        break;
        case '0013':
          message = this.errorTranslation.e_0013;
        break;
        case '0014':
          message = this.errorTranslation.e_0014;
        break;
        case '0016':
          message = this.errorTranslation.e_0016;
        break;
        case '0017':
          message = this.errorTranslation.e_0017;
        break;
        case '0022':
          message = this.errorTranslation.e_0022;
        break;
        case '0023':
          message = this.errorTranslation.e_0023;
        break;
        case '0024':
          message = this.errorTranslation.e_0024;
        break;
        case '0025':
          message = this.errorTranslation.e_0025;
        break;
        case '0027':
          message = this.errorTranslation.e_0027;
        break;
        case '0028':
          message = this.errorTranslation.e_0028;
        break;
        case '0029':
          message = this.errorTranslation.e_0029;
        break;
        case '0030':
          message = this.errorTranslation.e_0030;
        break;
        case '0031':
          message = this.errorTranslation.e_0031;
        break;
        case '0032':
          message = this.errorTranslation.e_0032;
        break;
        case '0033':
          message = this.errorTranslation.e_0033;
        break;
        case '0034':
          message = this.errorTranslation.e_0034;
        break;
        case '0035':
          message = this.errorTranslation.e_0035;
        break;
        case '0036':
          message = this.errorTranslation.e_0036;
        break;
        case '0037':
          message = this.errorTranslation.e_0037;
        break;
        case '0038':
          message = this.errorTranslation.e_0038;
        break;
        case '0039':
          message = this.errorTranslation.e_0039;
        break;
        case '0048':
          message = this.errorTranslation.e_0048;
        break;
        case '0053':
          message = this.errorTranslation.e_0053;
        break;
        case '0079':
          message = this.errorTranslation.e_0079;
        break;
        default:
          message = this.errorTranslation.generic_error;
        break;
      }
      return message;
    }

    /**
     * Method that checks whether an attribute key begins with a certain string
     * 
     * att.key can be both a string (used as is), or an array (only first element considered)
     */
    checkStartsWith(att: any, start: string) {
      if (att && att.key && att.key.length && att.key[0]?.itemName) {
        return (typeof att.key === 'string')
          ? att.key.startsWith(start)
          : att.key[0].itemName.startsWith(start);
      } else {
        return false;
      }
    }

}
