import { UserService } from './../../core/services/user.service';
import { Metadata } from './../../core/interfaces/reseller.interface';
import { CodeRole } from './../../core/interfaces/user.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { APPCONFIG } from '../../app.config';
import { MenuService } from '../../core/menu/menu.service';
import { MENU } from '../../routes/menu';
import { StatOrder } from '../../routes/menu';
import { StatProduct } from '../../routes/menu';
import { OblAdd } from '../../routes/menu';
import { OblList } from '../../routes/menu';
import { OwnerSection } from '../../routes/menu';
import { MultiselectItem } from '../../core/interfaces/admin.interface';
import { Product, ProductGroupFamily } from '../../core/interfaces/product-sale.interface';
import { CodeFamily, TranslationAttribute, TranslationAttributeImageKey } from '../../core/interfaces/product.interface';
import { AdditionalInfoProduct, CartItem } from '../../core/interfaces/cart-item.interface';
import { ProductService } from '../../core/services/product.service';
import { environment } from '../../../environments/environment';
import { IInvalidDatesRange } from '../models/price';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public userInfo = null;
  public lng = new BehaviorSubject<string>('');
  
  dialogObservable = this.lng.asObservable();

  _MENU = JSON.parse(JSON.stringify(MENU));

  constructor(
    public translationService: TranslateService,
    public menuService: MenuService,
    private userService: UserService,
    private productService: ProductService
  ) {
    this.setLoggedUserInfo();
  }

  changeDialogState(value: string): void {
    this.lng.next(value);
  }

  setLoggedUserInfo() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      APPCONFIG.userRole = this.userInfo.role;

      this.userService.getUserPrivilige().subscribe(res => {
        const { data, outcome } = res;
        this._MENU = JSON.parse(JSON.stringify(MENU));
        this.userInfo.role ? this.setUserMenu(this.userInfo.role, data) : localStorage.clear();
      }, err => { });

    } else {
      this.userInfo = null;
    }
  }

  getLoggedUserInfo() {
    return this.userInfo;
  }

  isLoggedUserAdmin() {
    return this.userInfo.role === CodeRole.COMPANY_ADMIN || this.userInfo.role === CodeRole.SUPERADMIN
      ? true
      : false;
  }

  setUserMenu(role: string, metadata: Metadata) {
    switch (role) {
      case CodeRole.SUPERADMIN:
        this.setSAMenu(role);
        this.setAnalysisMenu(metadata, role);
        this.setObliterationMenu(metadata, role);
        this.menuService.addMenu(this._MENU[role]);
      break;
      case CodeRole.COMPANY_ADMIN:
      case CodeRole.RESELLER_ADMIN:
      case CodeRole.COCLI_USER:
        this.setAnalysisMenu(metadata, role);
        this.setObliterationMenu(metadata, role);
        this.menuService.addMenu(this._MENU[role]);
        break;
      case CodeRole.COMPANY_WORKER:
        metadata.feOblAdd = false;
        this.setAnalysisMenu(metadata, role);
        this.setObliterationMenu(metadata, role);
        this.menuService.addMenu(this._MENU[role]);
        break;
    }
  }

  setAnalysisMenu(metadata: Metadata, role: string) {
    const idx = this._MENU[role].findIndex(el => el.text === 'Analysis');
    if (idx > -1) {
      this._MENU[role][idx].submenu = [];

      if (metadata.feStatOrder) { this._MENU[role][idx].submenu.push(StatOrder); }
      if (metadata.feStatProd) { this._MENU[role][idx].submenu.push(StatProduct); }

      if (this._MENU[role][idx].submenu.length === 0) {
        this._MENU[role].splice(idx, 1);
      }
    }
  }

  setObliterationMenu(metadata: Metadata, role: string) {
    const idx = this._MENU[role].findIndex(el => el.text === 'Obliteration');
    if (idx > -1) {
      this._MENU[role][idx].submenu = [];

      if (metadata.feOblAdd) { this._MENU[role][idx].submenu.push(OblAdd); }
      if (metadata.feOblList) { this._MENU[role][idx].submenu.push(OblList); }

      if (this._MENU[role][idx].submenu.length === 0) {
        this._MENU[role].splice(idx, 1);
      }
    }
  }

  setSAMenu(role: string) {
    const idx = this._MENU[role].findIndex(el => el.text === 'Products');
    if (idx > -1) {
      this._MENU[role][idx].submenu.push(OwnerSection);

      if (this._MENU[role][idx].submenu.length === 0) {
        this._MENU[role].splice(idx, 1);
      }
    }
  }

  brkRef(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  getProductTranslatedString(obj: Array<any> , translationObj: Object, currentLang: string, codeFamily, ) {
    const parsedObj = this.brkRef(obj);
    parsedObj.map( x => {
      if ( x.translate && x.translate !== '' && translationObj[currentLang][codeFamily]) {
        x.text = translationObj[currentLang][codeFamily][x.translate];
      } else {
        x.text = ""
      }
      return x;
    });
    return parsedObj;
  }

  getGenericTranslatedString(obj: Array<any>, parent: string, key: string, lang: string) {
    return obj[lang][parent][key];
  }

  getTableEntryStyle(collapseList: boolean[], entryIndex: number): any {
    if (collapseList[entryIndex]) {
      return {
        'color': '#23b7e5',
        'background': '#51b7e60d',
        'border-top': '2px solid #23b7e533',
        'border-left': '1px solid #23b7e533',
        'border-right': '1px solid #23b7e533'
      };
    } else {
      return {};
    }
  }

  /** Method that generates a list of objects for a multiselect element, depending on the specified selectType (faqTypes, languages) */
  generateMultiselectContent(selectType:string, withAll: boolean = false): MultiselectItem[] {
    const arr = this.getMultiselectContent(selectType);

    if (withAll) {
      arr.unshift({ id: 0, itemName: this.translationService.instant('state.all') })
    }

    return arr;
  }

  isToday(date: Date) {
    return this.sameDate(date, new Date());
  }

  sameDate(date1: Date, date2: Date) {
    return date1?.setHours(0, 0, 0, 0) === date2?.setHours(0, 0, 0, 0);
  }

  /**
   * Method that returns a product logo defined on the backoffice
   * 
   * At the moment the path to gcp repository has to be manually added if not specified by default
   */
  getProductLogo(product: Product | CartItem | AdditionalInfoProduct, requiredFallback: boolean = true): string {
    if (product && product.translationAttribute && product.translationAttribute.length) {
      const productImg = product.translationAttribute.find(el => el.key === TranslationAttributeImageKey.IMG_PRODUCT_LOGO);
      return productImg
        ? (productImg.value.includes(environment.cdnImagesUrl)
          ? productImg.value
          : environment.cdnImagesUrl + productImg.value
        ) : requiredFallback ? '../../../../../../assets/img/sacbo_logo.png' : null;
    } else {
      return requiredFallback ? '../../../../../../assets/img/sacbo_logo.png' : null;
    }
  }

  /** Method that returns all the available product images with a certain key and assigned to the current language */
  getProductSpecificImages(product: Product | CartItem | AdditionalInfoProduct, keyAttribute: string): string[] {
    if (product && product.translationAttribute && product.translationAttribute.length) {
      return this.manageProductFamilyImgs(product, keyAttribute);
    } else {
      return [];
    }
  }

  /** Method that returns the family logo from a product family */
  getProductFamilyLogo(family: ProductGroupFamily): string {
    if (family?.translationAttribute && !!family?.translationAttribute.length) {
      return family?.translationAttribute.find(ta => ta.key === TranslationAttributeImageKey.IMG_FAMILY_LOGO && ta.languageCode === this.translationService.currentLang)?.value;
    }
  }

  /** Method that retrieves all the family images with a certain key attribute after getting family info with its id */
  getProductFamilySpecificImages(idProductFamily: number, keyAttribute: string): Promise<string[]> {
    return new Promise((res, rej) => {
      this.productService.getProductFamilyById(idProductFamily).subscribe(response => {
        const familyImgs = this.manageProductFamilyImgs(response.data, keyAttribute);
        res(familyImgs);
      }, () => {
        rej([]);
      });
    })
  }

  /** Method that returns a color value based on the product family */
  getProductQrcodeColor(codeFamily: string): string {
    switch(codeFamily) {
      case CodeFamily.BATT_VOLO:
        return '#1e94f6';
      case CodeFamily.FAST_TRACK:
        return '#009881';
      case CodeFamily.FAST_TRACK_PLUS:
        return '#E73743';
      case CodeFamily.HONOR_CARD:
        return '#1e94f6';
      case CodeFamily.PARK:
        return '#009640';
      case CodeFamily.VIP_CARD:
        return '#1e94f6';
      case CodeFamily.VIP_LOUNGE:
      case CodeFamily.VIP_LOUNGE_HELLOSKY_LOUNGE:
      case CodeFamily.VIP_LOUNGE_HELLOSKY_LOUNGE_DOCCIA:
        return '#f8b000';
      default:
        return '#000000'
    }
  }

  /** Method that removes IMG and PDF properties from translationAttributes, since these are not required in this section */
  filterTranslationAttributes(translationAttributes: MultiselectItem[]): MultiselectItem[] {
    return translationAttributes.filter(
      tA => !tA.itemName.startsWith('IMG') && !tA.itemName.startsWith('PDF') 
    );
  }

  // --------- PRIVATE METHODS ---------- //

  /** Method that returns the list of multiselect items depending on the required select type */
  private getMultiselectContent(selectType: string): MultiselectItem[] {
    let arr: MultiselectItem[];

    switch (selectType) {
      case 'faqTypes':
        arr = [
          { id: 1, itemName: 'BO' },
          { id: 2, itemName: 'MP' }
        ];
      break;
      case 'languages':
        arr = [
          { id: 1, itemName: 'IT' },
          { id: 2, itemName: 'EN' },
        ];
      break
      default:
        arr = [];
    }

    return arr;
  }

  /** Method that groups the translation attributes of a product / family based on the key attribute and appends the GCP cdn path if required */
  private manageProductFamilyImgs(group: any, keyAttribute: string): string[] {
    const groupImgs = group.translationAttribute.filter(el => el.key === keyAttribute && el.languageCode === this.translationService.currentLang);
    return groupImgs.map(img => img.value.includes(environment.cdnImagesUrl) ? img.value : environment.cdnImagesUrl + img.value);
  }

  /** Method that transform date ranges into a list of dates */
  explodeInvalidDates(ranges: IInvalidDatesRange[]): Date[] {

    const dates: Date[] = [];

    ranges?.forEach(range => {
      
      const date = new Date(range.dateFrom);
      const to = new Date(range.dateTo);

      while(date <= to) {
        dates.push(new Date(date.toString()));
        date.setDate(date.getDate() + 1);
      }

    });

    return dates;

  }

}
