import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sacbo-product-sales-footer-section',
  templateUrl: './product-sales-footer-section.component.html',
  styleUrls: ['./product-sales-footer-section.component.scss']
})
export class ProductSalesFooterSectionComponent {

  @Input() amountDue: number;
  @Input() currency: string;
  @Input() disabled: boolean = true;
  @Output() cancelClicked =  new EventEmitter<void>();
  @Output() addToCartClicked =  new EventEmitter<void>();

  cancel() {
    this.cancelClicked.emit();
  }

  addToCart() {
    this.addToCartClicked.emit();
  }

}
