<div class="row align-items-end">

  <sacbo-withdrawal-select class="col-6" [(withdrawalSelected)]="inputForm.multiselect.withdrawal" (onSelected)="onWithdrawalSelect()"></sacbo-withdrawal-select>

  <div class="w-100"></div>

  <hr *ngIf="withdrawalInfo.withdrawalSelected === retireOption.HOME" />

  <div class="col-12">
    <div *ngIf="withdrawalInfo.withdrawalSelected === retireOption.HOME" class="row">

      <!-- Intestatario -->
      <div class="form-group col-sm-6">
        <label for="city">{{ 'form.name_surname' | translate }}</label>
        <input [(ngModel)]="withdrawalInfo.groupLeaderName"
          (ngModelChange)="validVipCard($event)"
          required
          type="text"
          name="groupLeaderName"
          placeholder="{{'form.name_surname' | translate }}"
          class="form-control"
          ngModel
          #groupLeaderName="ngModel">
      </div>
  
      <div class="form-group col-sm-6">
        <label for="city">{{ 'form.city' | translate }}</label>
        <input [(ngModel)]="withdrawalInfo.city"
          (ngModelChange)="validVipCard($event)"
          required
          type="text"
          name="city"
          placeholder="{{'form.city' | translate }}"
          class="form-control"
          ngModel
          #city="ngModel">
      </div>

      <div class="form-group col-sm-6">
        <label for="province">{{ 'form.province' | translate }}</label>
        <input [(ngModel)]="withdrawalInfo.province"
            (ngModelChange)="validVipCard($event)"
            required
            type="text"
            placeholder="{{ 'form.province' | translate }}"
            name="province"
            class="form-control"
            ngModel
            #province="ngModel">
      </div>

      <div class="form-group col-sm-6">
        <label for="zipCode">{{ 'form.zip_code' | translate }}</label>
        <input [(ngModel)]="withdrawalInfo.zipCode"
            (ngModelChange)="validVipCard($event)"
            required
            type="text"
            name="zipCode"
            placeholder="{{ 'form.zip_code' | translate }}"
            class="form-control"
            ngModel
            #zipCode="ngModel">
      </div>

      <div class="form-group col-sm-6">
          <label for="address">{{ 'form.address' | translate }}</label>
          <input [(ngModel)]="withdrawalInfo.address"
              (ngModelChange)="validVipCard($event)"
              required
              type="text"
              name="address"
              placeholder="{{ 'form.address' | translate }}"
              class="form-control"
              ngModel
              #address="ngModel">
      </div>
  
    </div>
  </div>

  <ng-container *ngIf="quantityArray.length == product.thresholdAttribute.length">
    <ng-container *ngFor="let thAt of product.thresholdAttribute; let i = index">
      <div class="col-12" *ngIf="quantityArray[i].quantity > 0">
        <label for="code">{{ thAt.name }}</label>
        <div class="row">
          <ng-container *ngFor="let card of holdersArray[i]; let j = index">
            <div class="form-group col-6">
              <label for="name">{{ 'form.account_holder' | translate }}</label>
              <input [(ngModel)]="card.accountHolder"
                  (ngModelChange)="validVipCard($event)"
                  required
                  type="text"
                  placeholder="{{'form.enter_name_holder' | translate}}"
                  name="{{i}}-{{j}}-account-holder"
                  class="form-control"
                  ngModel
                  #accountHolder="ngModel">
            </div>
          </ng-container> 
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>
