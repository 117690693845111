import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, Multiselect } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';

@Component({
  selector: 'sacbo-price-list-select',
  templateUrl: './price-list-select.component.html',
  styleUrls: ['./price-list-select.component.scss']
})
export class PriceListSelectComponent implements OnInit {

  @Input() set preselectedLists(newValue: number[]) {
    this.selectFromId(newValue);
  }

  @Input() set singleSelection(newValue: boolean) {
    this.multiselectSettings_base.singleSelection = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };
  }

  @Input() set disabled(newValue: boolean) {  
    this.multiselectSettings_base.disabled = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };
  }

  @Input() listsSelected: MultiselectItem[] = [];

  @Output() listsSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  @Input() set idReseller(newValue: number) {
    this.load(newValue);
  }

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  list: MultiselectItem[] = [];

  preselectedIds: number[];

  constructor(public portalService: PortalService) { 

    this.load();

  }

  ngOnInit(): void { }

  onElementSelect(): void {
    this.listsSelectedChange.emit(this.listsSelected);
    this.onSelected.emit(this.listsSelected);
  }

  load(idReseller: number = null) {

    let $api;

    if (idReseller) {
      $api = this.portalService.getMultiselectItemsWithParam(Multiselect.PRICE_LIST_RESELLER_IS_COMPANY, idReseller);
    } else {
      $api = this.portalService.getMultiselectItems(Multiselect.PRICE_LIST_RESELLER_IS_COMPANY);
    }

    $api.subscribe((response) => {

      this.list = response?.data.map(family => {

        return {
          id: family.id,
          itemName: family.name
        } as MultiselectItem

      })

      if (this.preselectedIds?.length && this.listsSelected.length == 0) {
        this.selectFromId(this.preselectedIds);
      }

    },
    (error) => {
      console.log("errore", error);
    });

  }

  selectFromId(ids: number[], withEvent: boolean = true) {

    this.preselectedIds = ids;

    this.listsSelected = this.list.filter(c => ids?.includes(c.id));

    if (withEvent) {
      this.onElementSelect();
    }

  }

}
