<div id="refund-modal">
  <!-- MODAL HEADER -->
  <div class="modal-header align-items-center">
    <div class="d-flex">
      <h4 class="modal-title pull-left">
        {{ 'refund_modal_component.title' | translate }}
      </h4>
    </div>
    <div class="d-flex">
      <button class="close pull-right ml-2"
        type="button"
        aria-label="close"
        (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <!-- DELETABLE ORDERS DETAILS -->
  <div class="modal-body modal-ticket-list py-md-4">
    <div class="title-section">
      <div class="title">
        {{ 'refund_modal_component.order_no' | translate: { orderNumber } }}
      </div>
      <div *ngIf="ticketList.length > 1"
        class="sub-title">
        {{ 'refund_modal_component.ticket_select' | translate }}
      </div>
    </div>
    <div class="refund-list mt-4">
      <div *ngFor="let ticket of ticketList">
        <app-refund-detail-item
          [deletableTicket]="ticket"
          [selectedTicketId]="selectedTicketId"
          (onSelect)="ticketSelect($event)"
        ></app-refund-detail-item>
      </div>
    </div>
  </div>
  
  <!-- MODAL FOOTER -->
  <div class="modal-footer">
    <button class="btn-sm btn-cancel mr-3"
      type="button"
      (click)="close()">
      {{ 'base.close' | translate | uppercase }}
    </button>
    <button class="btn-sm btn-action"
      type="button"
      [disabled]="!selectedTicketId"
      (click)="refund()">
      {{ 'base.refund' | translate | uppercase }}
    </button>
  </div>
</div>