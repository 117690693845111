import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { ProductService } from '../../../core/services/product.service';

@Component({
  selector: 'sacbo-product-threshold-select',
  templateUrl: './product-threshold-select.component.html',
  styleUrls: ['./product-threshold-select.component.scss']
})
export class ProductThresholdSelectComponent implements OnInit {

  @Input() set preselectedThresholds(newValue: number[]) {
    this.selectFromId(newValue);
  }

  @Input() set idProduct(newValue: number) {
    this.load(newValue);
  }

  @Input() set singleSelection(newValue: boolean) {
    this.multiselectSettings_base.singleSelection = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };
  }

  @Input() set disabled(newValue: boolean) {  
    this.multiselectSettings_base.disabled = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };
  }

  @Input() thresholdsSelected: MultiselectItem[] = [];

  @Output() thresholdsSelectedChange = new EventEmitter<MultiselectItem[]>();
  @Output() onSelected = new EventEmitter<MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false
  };

  list: MultiselectItem[] = [];

  preselectedIds: number[];

  constructor(private productService: ProductService) { 

  }

  ngOnInit(): void { }

  onElementSelect(): void {
    this.thresholdsSelectedChange.emit(this.thresholdsSelected);
    this.onSelected.emit(this.thresholdsSelected);
  }

  load(idProduct: number) {

    if (!idProduct) {
      return
    }

    this.productService.getProductById(idProduct).subscribe((response) => {

      this.list = response?.data.thresholdAttribute.map(threshold => {

        return {
          id: threshold.productThresholds?.length ? threshold.productThresholds[0].id : null,
          itemName: threshold.name
        } as MultiselectItem

      })

      if (this.preselectedIds?.length && this.thresholdsSelected.length == 0) {
        this.selectFromId(this.preselectedIds);
      }

    },
    (error) => {
      console.log("errore", error);
    });

  }

  selectFromId(ids: number[], withEvent: boolean = true) {

    this.preselectedIds = ids;

    this.thresholdsSelected = this.list.filter(c => ids?.includes(c.id));

    if (withEvent) {
      this.onElementSelect();
    }

  }

}
