export interface IDiscount {
  id?: number,
  name: string,
  state: DiscountState,
  validFrom: Date,
  validTo: Date,
  idCompany: number,
  priority: number,
  value: number,
  metadata: {
      idProducts: number[],
      idProductFamilies: number[],
      idPriceLists: number[],
      cart: boolean
  },
  type: DiscountType,
  typology: DiscountTypology,
  keyword: string
}

export enum DiscountState {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export enum DiscountType {
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE",
  FINAL_PRICE = "FINAL_PRICE"
}

export enum DiscountTypology {
  STANDARD = "STANDARD",
  KEYWORD = "KEYWORD"
}