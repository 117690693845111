import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Ticket } from '@app/core/interfaces/ticket.interface';
import { AppService } from '@app/core/services/base.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-refund-management-modal',
  templateUrl: './refund-management-modal.component.html',
  styleUrl: './refund-management-modal.component.scss'
})
export class RefundManagementModalComponent {

  @Input() orderNumber: string;
  @Input() ticketList: Ticket[];

  @Output() onRefund = new EventEmitter<void>();

  selectedTicketId: number;

  constructor(
    private modalService: BsModalService,
    private utils: AppService
  ) { }

  ticketSelect(deletableTicketId: number): void {
    this.selectedTicketId = deletableTicketId;
  }

  async refund(): Promise<void> {
    this.modalService.hide();
    await this.utils.refundServicePay(this.selectedTicketId);
    this.onRefund.emit();
  }

  close(): void {
    this.modalService.hide();
  }

}
