import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MultiselectItem } from '../../../core/interfaces/admin.interface';
import { TranslationAttribute } from '../../../core/interfaces/product.interface';
import { IProductUpdateImage, ProductService } from '../../../core/services/product.service';
import { IS1FileData } from '../../../s1/interfaces/s1-file-data.interfaces';
import { S1UIService } from '../../../s1/services/s1-ui.service';
import { S1FileType } from '../../../s1/components/s1-input-file/s1-input-file.component';
import { TranslationAttributePrefix } from '../translation-attribute-select/translation-attribute-select.component';

@Component({
  selector: 'sacbo-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.scss']
})
export class ImageUploadModalComponent implements OnInit {

  @Input() prefixAttributes: TranslationAttributePrefix[] = [TranslationAttributePrefix.all];
  @Input() excludedAttributes: TranslationAttributePrefix[];

  private updated = new Subject<boolean>();
  public updated$ = this.updated.asObservable();

  idProduct: number;
  media: TranslationAttribute;
  /** Param that defines whether the uploader must use family or product image APIs */
  familyImage: boolean;

  languageSelected: MultiselectItem[] = [];
  attributeSelected: MultiselectItem[] = [];
  fileSelected: IS1FileData;
  s1FileType = S1FileType;
  typesAccepted: S1FileType[] = [];

  translationAttributePrefix = TranslationAttributePrefix;

  get canUpload() {
    return this.fileSelected && this.attributeSelected.length && this.languageSelected.length && this.idProduct;
  }

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private productService: ProductService,
    public domSanitizer: DomSanitizer,
    private ui: S1UIService
  ) { }

  ngOnInit() {
    if (this.media) {
      this.attributeSelected = this.media.key;
      this.languageSelected = this.media.languageCode;
      this.filterFilesAccepted(this.attributeSelected);
    }
  }

  fileLoaded(file: IS1FileData) {
    this.fileSelected = file;
  }

  manageFilesizeError(): void {
    this.fileSelected = null;
  }

  upload() {

    const key = this.attributeSelected.length ? this.attributeSelected[0].itemName : null;
    const language = this.languageSelected.length ? this.languageSelected[0].id : null;

    const params: IProductUpdateImage = {
      /** When the image has to be connected to a family the required param is idProductFamily, otherwise idProduct */
      ...(this.familyImage) && { idProductFamily: +this.idProduct },
      ...(!this.familyImage) && { idProduct: +this.idProduct },
      // idProduct: +this.idProduct,
      key: key,
      enabled: true,
      extension: this.fileSelected.extension,
      fileEncoded: this.fileSelected.fileData,
      languageCode: language
    };

    if (this.media) {
      params["id"] = this.media.id;
    }

    this.ui.showSpinner();

    /** Different services are called depending on whether the image belongs to a product or a family */
    this.productService.updateProductImage(params, this.familyImage).subscribe(updated => {

      this.ui.closeSpinner();

      if (updated.outcome.success) {
        this.ui.showSuccessPopup("swal.media_uploaded");
        this.updated.next(true);
        this.updated.complete();
      }

    });

  }

  close() {
    this.updated.next(false);
    this.updated.complete();
  }

  filterFilesAccepted(attributes: MultiselectItem[]) {

    this.typesAccepted = attributes.map(att => {

      if (att.itemName.startsWith(TranslationAttributePrefix.image) || att.itemName.startsWith(TranslationAttributePrefix.pdfImgProduct) || att.itemName.startsWith(TranslationAttributePrefix.pdfImgFamily)) {
        return S1FileType.image
      } else if (att.itemName.startsWith(TranslationAttributePrefix.pdf)) {
        return S1FileType.pdf
      }

    })

  }

}
