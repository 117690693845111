import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Ticket } from '@app/core/interfaces/ticket.interface';

@Component({
  selector: 'app-refund-detail-item',
  templateUrl: './refund-detail-item.component.html',
  styleUrl: './refund-detail-item.component.scss'
})
export class RefundDetailItemComponent {

  @Input() deletableTicket: Ticket;
  @Input() selectedTicketId: number;

  @Output() onSelect = new EventEmitter<number>();

  Math = Math;

  constructor() { }

  onClickCheckButton(deletableTicketId: number) {
    if (deletableTicketId !== this.selectedTicketId) {
      this.onSelect.emit(deletableTicketId);
    }
  }

}
