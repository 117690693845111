<div class="d-flex flex-row flex-wrap mb-2">
  <!-- Selettore -->
  <div class="mr-2">
    <label class="sac-checkbox-button">
      <input (click)="onClickCheckButton(deletableTicket.id)"
        [checked]="deletableTicket.id === selectedTicketId"
        type="radio"
        class="sac-checkbox-button__input"
        id="choice"
        name="choice">
      <span class="sac-checkbox-button__control"></span>
    </label>
  </div>
  <!-- Dettagli ordine -->
  <div class="d-flex flex-column">
    <div class="d-flex flex-column align-items-start mb-2">
      <div class="name font-weight-bold">
        {{ deletableTicket.name }}
      </div>
      <div class="total">
        {{ 'order.columns.total' | translate | titlecase }}: {{ Math.abs(deletableTicket.additionalInfoPrice?.unitPrice) | currency: 'EUR' }}
      </div>
    </div>
  </div>
</div>
