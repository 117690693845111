import { SettingsService } from './../../../../core/settings/settings.service';
import { UserService } from './../../../../core/services/user.service';
import { CommonService } from './../../../../shared/services/common.service';
import { UserInfo, CodeRole } from './../../../../core/interfaces/user.interface';
import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { ToasterService } from 'angular-toaster';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {

  userProfileInfo: UserInfo = {};
  businessName: string = "";

  sidebarIsCollapsed: boolean = false;

  toastMessage: any;



  constructor(public userblockService: UserblockService, private commonService: CommonService,
    private userService: UserService, private toasterService: ToasterService,
    private settingService: SettingsService) {

    if (commonService.getLoggedUserInfo() !== null) {
      console.log(commonService.getLoggedUserInfo());
      const { name, surname, role, urlImg } = commonService.getLoggedUserInfo();
      this.userProfileInfo = {
        name: name,
        surname: surname,
        profilePicture: urlImg,
        codeRole: role
      };
    }
  }

  ngOnInit() {
    this.loadUserProfile();
    this.userService.getUserInfo().subscribe(res => {
      const { outcome, data } = res;
      if (outcome.success) {
        this.userProfileInfo = data ? data : null;
        this.userService.syncUserInfo( this.userProfileInfo );
      }
    }, err => {
      this.toasterService.pop('error', this.toastMessage.error, this.settingService.manageErrorMsg(err));
    });
  }

  loadUserProfile(): void {
    this.userService.getSyncUserInfo().subscribe(res => {
      if (res.name) {
        this.userProfileInfo = res;
      }
    });
  }

  updateImage(ev): void {
    this.userProfileInfo.profilePicture = '../../../../../../assets/img/blank-user.jpg';
    this.userService.syncUserInfo(this.userProfileInfo);
  }


  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

}
