import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountType } from '../../models/discount';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sacbo-discount-type-select',
  templateUrl: './discount-type-select.component.html',
  styleUrls: ['./discount-type-select.component.scss']
})
export class DiscountTypeSelectComponent implements OnInit {

  @Input() typeSelected: MultiselectItem[] = [];

  @Input() set disabled(newValue: boolean) {

    this.multiselectSettings_base.disabled = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };

  };

  @Output() typeSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  typeList: MultiselectItem[] = [];

  constructor(private translate: TranslateService) { 

    this.typeList = [
      { id: DiscountType.AMOUNT, itemName: this.translate.instant('multiselect.discountType.' + DiscountType.AMOUNT) },
      { id: DiscountType.PERCENTAGE, itemName: this.translate.instant('multiselect.discountType.' + DiscountType.PERCENTAGE) },
      { id: DiscountType.FINAL_PRICE, itemName: this.translate.instant('multiselect.discountType.' + DiscountType.FINAL_PRICE) }
    ]

  }

  ngOnInit(): void { }

  onTypeSelect(): void {
    this.typeSelectedChange.emit(this.typeSelected);
    this.onSelected.emit(this.typeSelected);
  }

}
