import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CouponUsageType } from '../../models/coupon';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sacbo-coupon-usage-type-select',
  templateUrl: './coupon-usage-type-select.component.html',
  styleUrls: ['./coupon-usage-type-select.component.scss']
})
export class CouponUsageTypeSelectComponent implements OnInit {

  @Input() typeSelected: MultiselectItem[] = [];

  @Output() typeSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  typeList: MultiselectItem[] = [];

  constructor(private translate: TranslateService) { 

    this.typeList = [
      { id: CouponUsageType.ALL, itemName: this.translate.instant('multiselect.usageType.' + CouponUsageType.ALL) },
      { id: CouponUsageType.CUSTOMER, itemName: this.translate.instant('multiselect.usageType.' + CouponUsageType.CUSTOMER) }/*,
      { id: CouponUsageType.USER, itemName: CouponUsageType.USER }*/
    ]

  }

  ngOnInit(): void { }

  onTypeSelect(): void {
    this.typeSelectedChange.emit(this.typeSelected);
    this.onSelected.emit(this.typeSelected);
  }

}
