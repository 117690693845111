import { formatDate, getLocaleDateFormat } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IDeletePriceInvalidDatesParams, IGetPriceInvalidDatesParams, ISetPriceInvalidDatesParams, PriceService } from '@app/core/services/price.service';
import { S1ButtonType } from '@app/s1/components/s1-button/s1-button.component';
import { S1UIService } from '@app/s1/services/s1-ui.service';
import { IInvalidDatesRange } from '@app/shared/models/price';

@Component({
  selector: 'sacbo-invalid-dates-product-price',
  templateUrl: './invalid-dates-product-price.component.html',
  styleUrl: './invalid-dates-product-price.component.scss'
})
export class InvalidDatesProductPriceComponent implements OnInit {

  @Input() idProduct: number;
  @Input() idPriceList: number;

  s1ButtonType = S1ButtonType;

  dateFrom: Date;
  dateTo: Date;

  invalidDates: IInvalidDatesRange[] = [];

  constructor(private priceService: PriceService, private ui: S1UIService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {

    const params: IGetPriceInvalidDatesParams = {
      idPriceList: this.idPriceList,
      idProduct: this.idProduct,
    }

    this.priceService.getInvalidDates(params).subscribe(dates => {
      this.invalidDates = dates;
    });

  }

  private setDates(dates: IInvalidDatesRange[], isDelete: boolean = false) {
    
    const params: ISetPriceInvalidDatesParams = {
      idPriceList: this.idPriceList,
      idProduct: this.idProduct,
      dateRanges: dates
    }

    this.priceService.setInvalidDates(params).subscribe(success => {

      if (success) {
        this.load();
        if (!isDelete) {
          this.dateFrom = null;
          this.dateTo = null;
        } 
        this.ui.showSuccessPopup("invalid_dates.popup." + (isDelete ? 'deleted' : 'created'));
      }
      
    });

  }

  add() {

    const newRange: IInvalidDatesRange = {
      dateFrom: formatDate(this.dateFrom, "YYYY-MM-dd", "it-IT"),
      dateTo: formatDate(this.dateTo, "YYYY-MM-dd", "it-IT")
    }

    this.setDates([...this.invalidDates, newRange]);

  }

  askDelete(index: number) {

    this.ui.showDialogPopup("invalid_dates.popup.delete").then(result => {

      if (result.value) {
        // Rimuovo l'elemento selezionato
        const safeCopy = [...this.invalidDates];
        safeCopy.splice(index, 1);

        if (safeCopy.length) {
          this.setDates(safeCopy, true);
        } else {
          this.deleteLast();
        }

      }
    })
    
  }

  private deleteLast() {

    const params: IDeletePriceInvalidDatesParams = {
      idPriceList: this.idPriceList,
      idProduct: this.idProduct
    }

    this.priceService.deleteInvalidDates(params).subscribe(success => {

      if (success) {
        this.load();
        this.ui.showSuccessPopup("invalid_dates.popup.deleted");
      }
      
    });

  }

}
