import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { SortByKeyPipe } from './pipes/sort-by-key.pipe';

import  { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { S1Module } from '../s1/s1.module';
import { ThresholdSectionComponent } from './components/threshold-section/threshold-section.component';
import { AccessCannelSectionComponent } from './components/access-cannel-section/access-cannel-section.component';
import { TranslationAttributeSectionComponent } from './components/translation-attribute-section/translation-attribute-section.component';
import { AngularMultiSelectModule } from '@khajegan/angular2-multiselect-dropdown';
import { ProductSalesThresholdSectionComponent } from './components/product-sales-threshold-section/product-sales-threshold-section.component';
import { ProductSalesVipCardSectionComponent } from './components/product-sales-vip-card-section/product-sales-vip-card-section.component';
import { WithdrawalSelectComponent } from './components/withdrawal-select/withdrawal-select.component';
import { ProductSalesBgyTopSectionComponent } from './components/product-sales-bgy-top-section/product-sales-bgy-top-section.component';
import { ProductSalesBattesimoVoloSectionComponent } from './components/product-sales-battesimo-volo-section/product-sales-battesimo-volo-section.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { KeysPipe } from './pipes/keys.pipe';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { ImagesUploadSectionComponent } from './components/images-upload-section/images-upload-section.component';
import { ImageUploadModalComponent } from './components/image-upload-modal/image-upload-modal.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { TranslationAttributeSelectComponent } from './components/translation-attribute-select/translation-attribute-select.component';
import { FilterContainerComponent } from './components/filter-container/filter-container.component';
import { RadioStateComponent } from './components/radio-state/radio-state.component';
import { MediaKeySelectComponent } from './components/media-key-select/media-key-select.component';
import { StateSelectComponent } from './components/state-select/state-select.component';
import { CoveredPipe } from './pipes/covered.pipe';
import { CouponTypeSelectComponent } from './components/coupon-type-select/coupon-type-select.component';
import { CouponUsageTypeSelectComponent } from './components/coupon-usage-type-select/coupon-usage-type-select.component';
import { CustomerSelectComponent } from './components/customer-select/customer-select.component';
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { CouponUsageStateTagComponent } from './components/coupon-usage-state-tag/coupon-usage-state-tag.component';
import { DiscountTypeSelectComponent } from './components/discount-type-select/discount-type-select.component';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { ProductFamilySelectComponent } from './components/product-family-select/product-family-select.component';
import { PriceListSelectComponent } from './components/price-list-select/price-list-select.component';
import { ProductDiscountsModalComponent } from './components/product-discounts-modal/product-discounts-modal.component';
import { DiscountStateTagComponent } from './components/discount-state-tag/discount-state-tag.component';
import { PorteraggioDetailSectionComponent } from './components/porteraggio-detail-section/porteraggio-detail-section.component';
import { ObliterationStatusBadgeComponent } from './components/obliteration-status-badge/obliteration-status-badge.component';
import { LoadingCircleComponent } from './components/loading-circle/loading-circle.component';
import { ParkingResultCardComponent } from './components/parking-result-card/parking-result-card.component';
import { PriceSortTabsComponent } from './components/price-sort-tabs/price-sort-tabs.component';
import { ParkDatetimeSelectComponent } from './components/park-datetime-select/park-datetime-select.component';
import { ItalianTimePipe } from './pipes/italian-time.pipe';
import { FreeTicketSectionComponent } from './components/free-ticket-section/free-ticket-section.component';
import { UserResellerSelectComponent } from './components/user-reseller-select/user-reseller-select.component';
import { FreeTicketElementComponent } from './components/free-ticket-element/free-ticket-element.component';
import { ProductThresholdSelectComponent } from './components/product-threshold-select/product-threshold-select.component';
import { ProductSalesHeaderSectionComponent } from './components/product-sales-header-section/product-sales-header-section.component';
import { ProductSalesFooterSectionComponent } from './components/product-sales-footer-section/product-sales-footer-section.component';
import { QuantitySelectorComponent } from './components/quantity-selector/quantity-selector.component';
import { DiscountTypologySelectComponent } from './components/discount-typology-select/discount-typology-select.component';
import { RefundManagementModalComponent } from './components/refund-management-modal/refund-management-modal.component';
import { RefundDetailItemComponent } from './components/refund-detail-item/refund-detail-item.component';
import { InvalidDatesProductPriceComponent } from './components/invalid-dates-product-price/invalid-dates-product-price.component';
defineLocale( 'it', itLocale );

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        S1Module,
        AngularMultiSelectModule,
        NgxSummernoteModule
    ],
    providers: [
        ColorsService,
        CoveredPipe,
        DatePipe,
        ItalianTimePipe
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        SortByKeyPipe,
        AccessCannelSectionComponent,
        ThresholdSectionComponent,
        TranslationAttributeSectionComponent,
        ProductSalesThresholdSectionComponent,
        ProductSalesVipCardSectionComponent,
        WithdrawalSelectComponent,
        ProductSalesBgyTopSectionComponent,
        ProductSalesBattesimoVoloSectionComponent,
        KeysPipe,
        HtmlEditorComponent,
        ImagesUploadSectionComponent,
        ImageUploadModalComponent,
        LanguageSelectComponent,
        TranslationAttributeSelectComponent,
        FilterContainerComponent,
        RadioStateComponent,
        MediaKeySelectComponent,
        StateSelectComponent,
        CoveredPipe,
        CouponTypeSelectComponent,
        CouponUsageTypeSelectComponent,
        CustomerSelectComponent,
        CompanySelectComponent,
        UserSelectComponent,
        CouponUsageStateTagComponent,
        DiscountTypeSelectComponent,
        ProductSelectComponent,
        ProductFamilySelectComponent,
        PriceListSelectComponent,
        ProductDiscountsModalComponent,
        DiscountStateTagComponent,
        PorteraggioDetailSectionComponent,
        ObliterationStatusBadgeComponent,
        LoadingCircleComponent,
        ParkingResultCardComponent,
        PriceSortTabsComponent,
        ParkDatetimeSelectComponent,
        ItalianTimePipe,
        FreeTicketSectionComponent,
        UserResellerSelectComponent,
        FreeTicketElementComponent,
        ProductThresholdSelectComponent,
        ProductSalesHeaderSectionComponent,
        ProductSalesFooterSectionComponent,
        QuantitySelectorComponent,
        DiscountTypologySelectComponent,
        RefundManagementModalComponent,
        RefundDetailItemComponent,
        InvalidDatesProductPriceComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        FlotDirective,
        SparklineDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        SortByKeyPipe,
        S1Module,
        AccessCannelSectionComponent,
        ThresholdSectionComponent,
        TranslationAttributeSectionComponent,
        ProductSalesThresholdSectionComponent,
        ProductSalesVipCardSectionComponent,
        WithdrawalSelectComponent,
        ProductSalesBgyTopSectionComponent,
        ProductSalesBattesimoVoloSectionComponent,
        KeysPipe,
        NgxSummernoteModule,
        HtmlEditorComponent,
        ImagesUploadSectionComponent,
        ImageUploadModalComponent,
        LanguageSelectComponent,
        TranslationAttributeSelectComponent,
        FilterContainerComponent,
        RadioStateComponent,
        MediaKeySelectComponent,
        StateSelectComponent,
        CoveredPipe,
        CouponTypeSelectComponent,
        CouponUsageTypeSelectComponent,
        CustomerSelectComponent,
        CompanySelectComponent,
        UserSelectComponent,
        CouponUsageStateTagComponent,
        DiscountTypeSelectComponent,
        ProductSelectComponent,
        ProductFamilySelectComponent,
        PriceListSelectComponent,
        ProductDiscountsModalComponent,
        DiscountStateTagComponent,
        PorteraggioDetailSectionComponent,
        ObliterationStatusBadgeComponent,
        LoadingCircleComponent,
        ParkingResultCardComponent,
        PriceSortTabsComponent,
        ParkDatetimeSelectComponent,
        ItalianTimePipe,
        FreeTicketSectionComponent,
        UserResellerSelectComponent,
        FreeTicketElementComponent,
        ProductThresholdSelectComponent,
        ProductSalesHeaderSectionComponent,
        ProductSalesFooterSectionComponent,
        QuantitySelectorComponent,
        DiscountTypologySelectComponent,
        RefundManagementModalComponent,
        RefundDetailItemComponent,
        InvalidDatesProductPriceComponent
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
