import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductInCombo } from '../../../core/interfaces/product-sale.interface';
import { AccessChannel, AccessChannelInfo } from '../../../core/interfaces/product.interface';

@Component({
  selector: 'sacbo-access-cannel-section',
  templateUrl: './access-cannel-section.component.html',
  styleUrls: ['./access-cannel-section.component.scss']
})
export class AccessCannelSectionComponent implements OnInit {

  @Input() generateTicket: boolean;
  @Input() set product(newValue: ProductInCombo) {
    if (newValue) {
      this._product = newValue;

      this.obliterationList.forEach(ob => {

        if (ob.channelName === AccessChannel.ACALL) {

          this.channelAllIsSelected = this._product.obliterations[ob.channelName] != null;
          ob.quantity = this._product.obliterations[ob.channelName];
          ob.checked = this._product.obliterations[ob.channelName] != null;
          this.obliterationALLQuantity = ob.quantity;
        } else {
          ob.quantity = this._product.obliterations[ob.channelName];
          ob.checked = this._product.obliterations[ob.channelName] != null;
        }

      })

      this.accessChannelType = this.channelAllIsSelected ? AccessChannel.ACALL : null;

      this._product.obliterations = this.obliterationList;
      this.productChange.emit(this._product);

    }
  };

  @Output() productChange = new EventEmitter<ProductInCombo>();

  channelAllIsSelected: boolean = true;
  AccessChannel = AccessChannel;
  accessChannelType: AccessChannel = AccessChannel.ACALL;
  obliterationList: AccessChannelInfo[] = [];
  obliterationALLQuantity: number;

  _product: ProductInCombo;

  channelList = [
    { id: 0, itemName: 'ACALL' },
    { id: 1, itemName: 'FTSTK' },
    { id: 2, itemName: 'VLSCB' },
    { id: 3, itemName: 'VLGIS' }
  ]

  constructor() { 
    this.setObliterationList();
  }

  ngOnInit() { }

  setObliterationList(): void {
    this.obliterationList = [];
    this.channelList.forEach(el => {
      const temp: AccessChannelInfo = {
        channelName: el.itemName,
        quantity: 0,
        checked: el.itemName === AccessChannel.ACALL
      };
      this.obliterationList.push(temp);
    });
  }

  onChannelAllToggleSelect(event: any): void {
    if (event.target.checked) {
      this.channelAllIsSelected = true;
      this.accessChannelType = AccessChannel.ACALL;
      this.obliterationList.forEach(el => {
        if (el.channelName !== AccessChannel.ACALL) {
          el.quantity = 0;
        }
      });
    } else {
      this.channelAllIsSelected = false;
      this.accessChannelType = null;
      this.obliterationALLQuantity = 0;
    }
    this.obliterationList.find(el => el.channelName === AccessChannel.ACALL).checked = this.channelAllIsSelected;
    this.productChange.emit(this._product);
  }

  quantityObliterationChanged(idx, isAllChannel, value){

    if(isAllChannel){
      this.obliterationALLQuantity = value ? Math.abs(value) : this.obliterationALLQuantity;
      this.obliterationList[idx].quantity = this.obliterationALLQuantity;
    } else{
      this.obliterationList[idx].quantity = Math.abs(value) ;
    }
    this._product.obliterations = this.obliterationList;
    this.productChange.emit(this._product);
  }

  onSelectChannel(channel: AccessChannelInfo): void {
    const currentState = this.obliterationList.find(el => el.channelName === channel.channelName).checked;
    this.obliterationList.find(el => el.channelName === channel.channelName).checked = !currentState;
    this.productChange.emit(this._product);
  }

}
