import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Multiselect, MultiselectItem, MultiselectItems, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { ProductAction, TranslationAttribute } from '../../../core/interfaces/product.interface';
import { PortalService } from '../../../core/services/portal.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'sacbo-translation-attribute-section',
  templateUrl: './translation-attribute-section.component.html',
  styleUrls: ['./translation-attribute-section.component.scss']
})
export class TranslationAttributeSectionComponent implements OnInit {

  @Input() set _translationAttributeList(newValue: TranslationAttribute[]) {

    if (newValue) {
      this.translationAttributeList = newValue;
      this.translationAttributeList = this.translationAttributeList.filter(att => !this.settingService.checkStartsWith(att, 'IMG_') && !this.settingService.checkStartsWith(att, 'PDF_'));
      this.loadMultiselectItems();
    }

  };
  @Input() translationAttributeListValidity: boolean = true;
  @Input() typeActionProduct: ProductAction;
  @Input() idProduct: number;
  @Output() _translationAttributeListChange = new EventEmitter<TranslationAttribute[]>();
  @Output() translationAttributeListValidityChange = new EventEmitter<boolean>();

  translationAttributeList: TranslationAttribute[] = [];
  
  errorKeyTranslationAttribute: string;

  ProductAction = ProductAction;
  Multiselect = Multiselect;

  multiselectSettings_search: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3
  };

  multiselectItems: MultiselectItems = { 
    translationAttribute: [],
    languageCode: []
  }

  availableLanguages: any;

  constructor(
    public settingService: SettingsService,
    private translatorService: TranslatorService,
    private commonService: CommonService,
    private portalService: PortalService
  ) { }

  ngOnInit() { 
    this.loadMultiselectItems();
  }

  loadMultiselectItems(): void {
    if (this.typeActionProduct === ProductAction.EDIT_REGISTRY) {
      this.loadItemsById(Multiselect.TRANSLATION_ATTRIBUTE, this.idProduct);
    } else {
      this.loadItems(Multiselect.TRANSLATION_ATTRIBUTE);
    }
    this.availableLanguages = this.translatorService.getAvailableLanguages();
    this.multiselectItems.languageCode = [];
    for (const al in this.availableLanguages) {
      const temp: MultiselectItem = {
        id: this.availableLanguages[al].code,
        itemName: this.availableLanguages[al].text
      };
      this.multiselectItems.languageCode.push(temp);
    }
  }

  loadItems(multiselectType: Multiselect): void {
    this.portalService.getMultiselectItems(multiselectType).subscribe((response) => {
      const { data } = response;
      switch (multiselectType) {
        case Multiselect.TRANSLATION_ATTRIBUTE:
        case Multiselect.CHANNEL_LIST:
          this.multiselectItems[multiselectType] = this.portalService.getItemsFromDataNoId(data);
          break;
        default:
          this.multiselectItems[multiselectType] = this.portalService.getItemsFromData(data);
      }
      this.setProductInfoInInputForm();
    },
      (error) => {
        console.log("errore", error);
        //this.toasterService.pop('error', this.toastMessage.error, this.settingService.manageErrorMsg(error));
      });
  }

  loadItemsById(multiselectType: Multiselect, id: number): void {
    this.portalService.getMultiselectItemsWithParam(multiselectType, id).subscribe((response) => {
      const { data } = response;
      switch (multiselectType) {
        case Multiselect.TRANSLATION_ATTRIBUTE:
          this.multiselectItems[multiselectType] = this.portalService.getItemsFromDataNoId(data);
          break;
        default:
          this.multiselectItems[multiselectType] = this.portalService.getItemsFromData(data);
      }

      if (this.typeActionProduct !== ProductAction.NEW_PRODUCT) {
        this.setProductInfoInInputForm();
      }
    },
      (error) => {
        console.log("errore", error);
        //this.toasterService.pop('error', this.toastMessage.error, this.settingService.manageErrorMsg(error));
      });
  }

  addTranslationAttribute(): void {

    this.translationAttributeList.push({
      key: null,
      value: null,
      languageCode: null
    });
    this.translationAttributeList = this.commonService.brkRef(this.translationAttributeList);
    this.validTranslationAttributeList();
    this._translationAttributeListChange.emit(this.translationAttributeList);
    this.translationAttributeListValidityChange.emit(this.translationAttributeListValidity);
  }

  deleteTranslationAttribute(index: number): void {
    this.translationAttributeList.splice(index, 1);
    if (this.translationAttributeList.length === 0) {
      this.translationAttributeListValidity = true;
    }
    this.validTranslationAttributeList();
    this._translationAttributeListChange.emit(this.translationAttributeList);
    this.translationAttributeListValidityChange.emit(this.translationAttributeListValidity);
  }

  validTranslationAttributeList(): void {
    if (this.translationAttributeList.length > 1) {
      for (const att of this.translationAttributeList) {
        if (att.key !== null || att.languageCode !== null) {
          const res = this.translationAttributeList.filter(el =>
            JSON.stringify(att.key) === JSON.stringify(el.key) && JSON.stringify(att.languageCode) === JSON.stringify(el.languageCode)
          );
          if (res.length > 1) {
            this.errorKeyTranslationAttribute = "productManagement.label.errorTranslationAttribute";
            this.translationAttributeListValidity = false;
            return;
          }
        } else {
          this.errorKeyTranslationAttribute = "productManagement.label.noAttributeOrLanguageCode";
          this.translationAttributeListValidity = false;
          return;
        }
      }
    }
    this.translationAttributeListValidity = true;
    this._translationAttributeListChange.emit(this.translationAttributeList);
    this.translationAttributeListValidityChange.emit(this.translationAttributeListValidity);
  }

  setProductInfoInInputForm() {
    // this.translationAttributeList = [... this.translationAttributeList ]
  }

}
