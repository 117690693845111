import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResellerService } from '../../../core/services/reseller.service';
import { LoggedUserInfo, Multiselect, MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';
import { CommonService } from '../../services/common.service';
import { CodeRole } from '../../../core/interfaces/user.interface';

@Component({
  selector: 'sacbo-user-reseller-select',
  templateUrl: './user-reseller-select.component.html',
  styleUrls: ['./user-reseller-select.component.scss']
})
export class UserResellerSelectComponent implements OnInit {

  @Input() resellerSelected: MultiselectItem[] = [];
  @Input() set singleSelection(newValue: boolean) {
    this.multiselectSettings_base.singleSelection = !!newValue;
  }

  @Output() resellerSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  loggedUserInfo: LoggedUserInfo;

  CodeRole = CodeRole;

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3
  };

  resellerList: MultiselectItem[] = [];

  constructor(public resellerService: ResellerService, public portalService: PortalService, public commonService: CommonService) { 

    this.loggedUserInfo = this.commonService.getLoggedUserInfo();
    this.load();

  }

  ngOnInit(): void { }

  onResellerSelect(): void {
    this.resellerSelectedChange.emit(this.resellerSelected);
    this.onSelected.emit(this.resellerSelected);
  }

  load() {

    let $api;
    
    if (this.loggedUserInfo.role == CodeRole.SUPERADMIN) {
      $api = this.portalService.getMultiselectItems(Multiselect.RESELLER_IS_COMPANY);
    } else if (this.loggedUserInfo.role == CodeRole.COMPANY_ADMIN) { 
      $api = this.resellerService.getUserMyReseller()
    } else {
      return;
    }

    $api.subscribe((response) => {

      console.log("RES: ", response);

      this.resellerList = response?.data.map(reseller => {

        return {
          id: reseller.id,
          itemName: reseller.name
        } as MultiselectItem

      })

    },
    (error) => {
      console.log("errore", error);
    });

  }

  onDeselectReseller() {
    console.log("Deselected");
  }

}
