import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, State } from '../../../core/interfaces/admin.interface';

@Component({
  selector: 'sacbo-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss']
})
export class StateSelectComponent implements OnInit {

  @Input() stateSelected: MultiselectItem[] = [];
  @Output() stateSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();
  @Input() set singleSelection(newValue: boolean) {

    if (newValue != null) {
      this.multiselectSettings_base.singleSelection = newValue;
    }
    
  }

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false
  };

  statesList: MultiselectItem[] = [];

  constructor() { 

    this.statesList = [{ id: State.ACTIVE, itemName: 'Active' }, { id: State.INACTIVE, itemName: 'In Active' }];

  }

  ngOnInit() { }

  onStateSelect(): void {
    this.stateSelectedChange.emit(this.stateSelected);
    this.onSelected.emit(this.stateSelected);
  }

}
