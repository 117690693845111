import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S1Button } from './components/s1-button/s1-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { S1DatePickerComponent } from './components/s1-date-picker/s1-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { S1InputFileComponent } from './components/s1-input-file/s1-input-file.component';
import { S1Modal } from './components/s1-modal/s1-modal.component';
import { S1InputTextComponent } from './components/s1-input-text/s1-input-text.component';
import { S1ReadableDatePipe } from './pipes/s1-readable-date.pipe';
import { S1CurrencyPipe } from './pipes/s1-currency.pipe';
import { S1SpinnerComponent } from './components/s1-spinner/s1-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDatepickerModule
  ],
  declarations: [
    S1Button,
    S1DatePickerComponent,
    S1InputFileComponent,
    S1Modal,
    S1InputTextComponent,
    S1ReadableDatePipe,
    S1CurrencyPipe,
    S1SpinnerComponent
  ],
  exports: [
    S1Button,
    S1DatePickerComponent,
    S1InputFileComponent,
    S1Modal,
    S1InputTextComponent,
    S1ReadableDatePipe,
    S1CurrencyPipe,
    S1SpinnerComponent
  ],
  providers: [
    S1ReadableDatePipe,
    S1CurrencyPipe
  ]
})
export class S1Module { 

}
