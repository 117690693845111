import { Component, Input, OnInit } from '@angular/core';
import { DiscountState } from '../../models/discount';

@Component({
  selector: 'sacbo-discount-state-tag',
  templateUrl: './discount-state-tag.component.html',
  styleUrls: ['./discount-state-tag.component.scss']
})
export class DiscountStateTagComponent implements OnInit {

  @Input() state: DiscountState;

  discountState = DiscountState;

  constructor() { }

  ngOnInit(): void { }

}
