import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '@app/core/interfaces/admin.interface';
import { DiscountType, DiscountTypology } from '@app/shared/models/discount';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sacbo-discount-typology-select',
  templateUrl: './discount-typology-select.component.html',
  styleUrls: ['./discount-typology-select.component.scss']
})
export class DiscountTypologySelectComponent {

  @Input() typologySelected: MultiselectItem[] = [];

  @Input() set disabled(newValue: boolean) {

    this.multiselectSettings_base.disabled = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };

  };

  @Output() typologySelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  typeList: MultiselectItem[] = [];

  constructor(private translate: TranslateService) { 

    this.typeList = [
      { id: DiscountTypology.STANDARD, itemName: this.translate.instant('multiselect.discountTypology.' + DiscountTypology.STANDARD) },
      { id: DiscountTypology.KEYWORD, itemName: this.translate.instant('multiselect.discountTypology.' + DiscountTypology.KEYWORD) }
    ]

  }

  ngOnInit(): void { }

  onTypeSelect(): void {
    this.typologySelectedChange.emit(this.typologySelected);
    this.onSelected.emit(this.typologySelected);
  }

}
