import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRelatedProduct } from '../../models/related-product';
import { MultiselectItem } from '../../../core/interfaces/admin.interface';
import { RelatedProductApiService } from '../../services/related-product-api.service';
import { S1UIService } from '../../../s1/services/s1-ui.service';

@Component({
  selector: 'sacbo-free-ticket-element',
  templateUrl: './free-ticket-element.component.html',
  styleUrls: ['./free-ticket-element.component.scss']
})
export class FreeTicketElementComponent implements OnInit {

  @Input() mainProductId: number;
  @Input() set relatedProduct(newValue: IRelatedProduct) {

    this._relatedProduct = newValue;
    this.quantity = this._relatedProduct.quantity;

  };

  @Output() updated = new EventEmitter<boolean>();

  products: MultiselectItem[] = [];
  prices: MultiselectItem[] = [];
  resellers: MultiselectItem[] = [];
  thresholds: MultiselectItem[] = [];
  quantity: number = 0;
  _relatedProduct: IRelatedProduct;

  constructor(private relatedProductApiService: RelatedProductApiService, private ui: S1UIService) { }

  ngOnInit(): void {
  }

  save() {

    const relatedProduct: IRelatedProduct = {
      id: this._relatedProduct?.id,
      idProduct: +this.mainProductId,
      idPriceList: this.prices[0].id,
      idRelatedProduct: this.products[0].id,
      idProductThreshold: this.thresholds[0].id,
      quantity: +this.quantity,
      state: this._relatedProduct?.state
    }

    const api$ = this._relatedProduct ? this.relatedProductApiService.modifica(relatedProduct) : this.relatedProductApiService.crea(relatedProduct);
    const text = this._relatedProduct ? 'swal.related_updated' : 'swal.related_created';

    api$.subscribe(success => {

      if (success) {
        this.ui.showSuccessPopup(text);
        this.updated.emit(true);
        this.reset();
      }

    })

  }

  private reset() {
    this.products= [];
    this.prices = [];
    this.resellers = [];
    this.thresholds = [];
    this.quantity = 0;
  }

  askDelete() {
    this.ui.showDialogPopup("swal.related_ask_delete").then(accepted => {

      if (accepted.value) {

        this.relatedProductApiService.elimina(this._relatedProduct?.id).subscribe(deleted => {

          if (deleted) {
            this.ui.showSuccessPopup("swal.related_deleted");
            this.updated.emit(true);
          }

        });

      }

    });

  }

}
