import { TicketAction } from './../interfaces/ticket.interface';
import { SettingsService } from './../settings/settings.service';
import { LoggingService } from './log.service';
import { RestfulService } from './restful.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ticket } from '../interfaces/ticket.interface';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver/src/FileSaver.js';
import { getEndPointWithParams, dateFormatting } from '../../../assets/js/util.js';
import { RequestBody } from '../interfaces/admin.interface';
import { S1HttpClientService } from '../../s1/services/s1-http-client.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  ticket: Ticket;
  typeActionTicket: TicketAction;


  constructor(private restful: RestfulService, private logger: LoggingService,
    private settings: SettingsService, private s1HttpClient: S1HttpClientService) { }

  setTypeAction(typeActionTicket: TicketAction): void {
    this.typeActionTicket = typeActionTicket;
  }  

  getTypeAction(): TicketAction {
    return this.typeActionTicket;
  }

  getAnalysisProductList(reqBody: any): Observable<any> {
    const endPoint = ENDPOINT.ticket_all;
    return this.post(endPoint, reqBody);
  }

  editTicket(reqBody: any) {
    const endPoint = ENDPOINT.ticket_edit;
    return this.put(endPoint, reqBody);
  }

  disableTicket(idTicket: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.ticket_disable, idTicket);
    return this.delete(endPoint);
  }

  reactivateTicket(idTicket: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.ticket_reactivate, idTicket);
    return this.put(endPoint, null);
  }

  setTicketToEdit(ticket: Ticket): void {
    this.ticket = ticket;
  }

  getTicketToEdit(): Ticket {
    return this.ticket;
  }

  getTicketInfo(idTicket: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.ticket_singleInfo, idTicket);
    return this.get(endPoint);
  }

  downloadAnalysisTicketReport(reqBody: RequestBody): Observable<any> {
    let endPoint = ENDPOINT.ticket_allExport;
    return this.post_downloadFile(endPoint, reqBody);
  }

  sendReminder(idTicket: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.ticket_send_reminder, idTicket);
    return this.put(endPoint, null);
  }

  refundTicketServicepay(idTicket: number): Observable<boolean> {

    const endPoint = getEndPointWithParams(ENDPOINT.ticket_refund_servicepay, idTicket);

    return this.s1HttpClient.put(endPoint, {}).pipe(
      map(response => response.outcome.success)
    );

  }

  /** REST METHODS */

  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  post_downloadFile(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
        this.restful.post_downloadFile(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
            this.responseHandler_downloadFile(response, observer);
        },error =>{
            observer.error("download file error");
        });
    });
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

  responseHandler_downloadFile(response: HttpResponse<any>, observer: any): void {
    saveAs(response, "products_"+dateFormatting(new Date())+".xls");
    observer.next(response);
  }

}
