import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RetireOption, ThresholdAttributeInfo, VipCardItem } from '../../../core/interfaces/cart-item.interface';
import { Product } from '../../../core/interfaces/product-sale.interface';

export interface IWithdrawalInfo {
  address: string,
  zipCode: string,
  province: string,
  city: string,
  groupLeaderName: string,
  withdrawalSelected: RetireOption
}

@Component({
  selector: 'sacbo-product-sales-vip-card-section',
  templateUrl: './product-sales-vip-card-section.component.html',
  styleUrls: ['./product-sales-vip-card-section.component.scss']
})
export class ProductSalesVipCardSectionComponent implements OnInit {

  @Input() product: Product;

  @Input() quantityArray: ThresholdAttributeInfo[] = [];
  @Output() quantityArrayChange = new EventEmitter<ThresholdAttributeInfo[]>();

  @Input() holdersArray: VipCardItem[][] = [];
  @Output() holdersArrayChange = new EventEmitter<VipCardItem[][]>();

  @Input() withdrawalInfo: IWithdrawalInfo;
  @Output() withdrawalInfoChange = new EventEmitter<IWithdrawalInfo>();

  cardList: VipCardItem[] = [];
  addValidity: boolean = false;

  retireOption = RetireOption;

  inputForm: any = {
    multiselect: {
      withdrawal: [],
      province: [],
      city: [],
    }
  };

  constructor() { }

  ngOnInit() {
  }

  onWithdrawalSelect(): void {
    console.log("VAL: ", this.inputForm.multiselect.withdrawal);
    this.withdrawalInfo.withdrawalSelected = this.inputForm.multiselect.withdrawal[0].itemName;
    this.validVipCard();
  }

  validVipCard(value?): void {

    this.withdrawalInfoChange.emit(this.withdrawalInfo);
    this.holdersArrayChange.emit(this.holdersArray);
    this.quantityArrayChange.emit(this.quantityArray);

    this.addValidity = false;

    const retire = this.inputForm.multiselect.retireOptionType !== null || this.inputForm.multiselect.retireOptionType !== '';

    const list = this.cardList.every(el => el.idAttribute !== null && el.accountHolder !== null && el.accountHolder !== '');

    if (this.inputForm.multiselect.withdrawal[0].itemName) {
      if (this.inputForm.multiselect.withdrawal[0].itemName === RetireOption.HOME.toUpperCase()) {
        const city = this.withdrawalInfo.city !== null && this.withdrawalInfo.city !== '';
        const province = this.withdrawalInfo.province !== null && this.withdrawalInfo.province !== '';
        const zipCode = this.withdrawalInfo.zipCode !== null && this.withdrawalInfo.zipCode !== '';
        const address = this.withdrawalInfo.address !== null && this.withdrawalInfo.address !== '';
        if (this.cardList.length > 0 && retire && city && province && zipCode && address && list) {
          this.addValidity = true;
          return;
        }
      } else {
        if (this.cardList.length > 0 && retire && list) {
          this.addValidity = true;
          return;
        }
      }
    }
  }

}
