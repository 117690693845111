<s1-modal #modal [title]="'menu.discounts_list'">
  <div body class="card card-default">
  
    <table class="table table-hover pt-2">
      <thead>
        <th>{{ 'columns.name' | translate | uppercase}}</th>
        <th>{{ 'columns.validity' | translate | uppercase}}</th>
        <th>{{ 'columns.value' | translate | uppercase}}</th>
        <th>{{ 'columns.priority' | translate | uppercase}}</th>
        <th>{{ 'columns.state' | translate | uppercase}}</th>
      </thead>
      <tbody>
        <tr *ngFor="let el of elementList ; let i = index;">
          <td><b>{{ el.name }}</b><br/>{{ el.type == discountType.PERCENTAGE ? '%' : 'valore fisso' }}</td>
          <td>Dal: {{ el.validFrom | s1ReadableDate }}<br/>Al: {{ el.validTo | s1ReadableDate }}</td>
          <td>{{ el.value }} {{ el.type == discountType.PERCENTAGE ? '%' : '&euro;' }}</td>
          <td>{{ el.priority }}</td>
          <td><sacbo-discount-state-tag [state]="el.state"></sacbo-discount-state-tag></td>
        </tr>
      </tbody>
      <tfoot *ngIf="elementList?.length==0">
          <tr>
            <td colspan="5" class="text-center">
                {{ 'record_not_found.discounts' | translate }}
            </td>
          </tr>
      </tfoot>
    </table>
    
    <div class="card-footer">
        <div class="d-flex float-right">
            <div class="text-right">
                <pagination
                    class="pagination-sm"
                    [totalItems]="tableInfo.total"
                    [itemsPerPage]="tableInfo.row"
                    [boundaryLinks]="true"
                    [rotate]="false"
                    (pageChanged)="onChangePage($event)"
                    [maxSize]="5">
                </pagination>
            </div>
        </div>
    </div>
  </div>
</s1-modal>